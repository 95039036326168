import Vue from 'vue'
import Vuex, { ModuleTree } from 'vuex'
import core from './module/core'
import insightClients from './module/insightClients'
import groups from "./module/groups"
import privileges from "./module/privileges"
import users from "./module/users"
import subscriptions from "./module/subscriptions"
import features from "./module/features"
import { RootState } from './RootState'

Vue.use(Vuex)

export const modules: ModuleTree<RootState> = {
  core,
  insightClients,
  groups,
  privileges,
  users,
  subscriptions,
  features,
}

// create the Vuex instance by combining the state and mutations objects
// then export the Vuex store for use by our components
export default new Vuex.Store({
  modules
})
