import { UpdatableEntity } from "./UpdatableEntity";

export class DeletableEntity extends UpdatableEntity {
  constructor(
    createdAt?: string,
    createdBy = '',
    modifiedAt?: string,
    modifiedBy = '',
    public deletedAt?: string,
    public deletedBy = '',
  ) {
    super(createdAt, createdBy, modifiedAt, modifiedBy);
  }
}