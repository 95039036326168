
import Vue from "vue"
import SubscriptionTile from "./SubscriptionTile.vue"
import { PropValidator } from 'vue/types/options'
import { Subscription } from '@/models/subscriptions/Subscription'

export default Vue.extend({
  components: {
    SubscriptionTile,
  },

  props: {
    subscriptions: {
      type: Array,
      default: null,
    } as PropValidator<Subscription[]>,

    updatingSubscriptionId: {
      type: String,
      default: '',
    },
  },
})
