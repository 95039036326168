import Router from 'vue-router'
import routes from './routes'
import { navGuard } from './NavGuard'

const router = new Router({
    mode: 'history',
    routes
});

//Ensure we're authorized before navigating to a route
router.beforeEach((to, from, next) => {
    navGuard(router.app.$auth, to, from, next)
})

export default router
