//CORE
export const CORE_FETCH_APP_CONFIG = "coreFetchAppConfig"

//Insight Clients
export const CLIENT_FETCH_ALL = "clientFetchAll"
export const CLIENT_SAVE = "clientSave"
export const CLIENT_DELETE = "clientDelete"

//Groups
export const GROUP_FETCH_ALL = "groupFetchAll"
export const GROUP_LOAD_GROUP = "groupLoadGroup"
export const GROUP_SAVE_GROUP = "groupSaveGroup"
export const GROUP_DELETE = "groupDelete"

//Privileges
export const PRIVILEGE_FETCH_ALL = "privilegeFetchAll"

//Users
export const USER_FETCH_ALL = "userFetchAll"
export const USER_LOAD_USER = "userLoadUser"
export const USER_UNLINK = "userUnlink"
export const USER_LINK = "userLink"
export const USER_ASSIGN_GROUP = "userAssignGroup"
export const USER_REMOVE_GROUP = "userRemoveGroup"
export const USER_INVITE = "userInvite"
export const USER_DELETE = "userDelete"
export const USER_UNDELETE = "userUndelete"

//Subscriptions
export const SUBSCRIPTION_FETCH_ALL = "subscriptionsFetchAll"
export const SUBSCRIPTION_CREATE = "subscriptionCreate"
export const SUBSCRIPTION_DELETE = "subscriptionDelete"
export const SUBSCRIPTION_UPDATE = "subscriptionUPdate"

//Features
export const FEATURE_FETCH_ALL_APPLICATIONS = "applicationFetchAll"
export const FEATURE_FETCH_ALL_FEATURES = "featureFetchAll"