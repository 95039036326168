
import * as ac from "../../store/actionNames"
import notifications from "../../util/notifications"
import BufferedEditor from "../common/BufferedEditor.vue"
import NameField from "../common/NameField.vue"
import DescriptionField from "../common/DescriptionField.vue"
import PrivilegeChooser from '../privileges/PrivilegeChooser.vue'
import Vue from "vue"
import { PropValidator } from "vue/types/options"
import { Group } from "@/models/groups/Group"

export default Vue.extend({

  components: {
    BufferedEditor,
    NameField,
    DescriptionField,
    PrivilegeChooser,
  },

  props: {
    group: {
      type: Object,
      default: null,
    } as PropValidator<Group>
  },

  data() {
    return {
      saveEditor: false,
      cancelEditor: false,
      formValid: false,
      saveInProgress: false,
      saveActionName: ac.GROUP_SAVE_GROUP,
      showDeleteConfirm: false,
      forceDelete: false,
    }
  },

  computed: {
    canDeleteGroup () {
      const auth = this.$auth

      return auth.hasPrivilegeAnyClient("5400") ||
        (auth.hasPrivilegeAnyClient("5410") && this.group.isProtected == false) ||
        (auth.hasPrivilegeForClient(this.group.client, "5420") && this.group.isProtected == false)
    },

    canEditGroup () {
      const auth = this.$auth

      return auth.hasPrivilegeAnyClient("5300") ||
        (auth.hasPrivilegeAnyClient("5310") && this.group.isProtected == false) ||
        (auth.hasPrivilegeForClient(this.group.client, "5320") && this.group.isProtected == false)
    },

    canSetProtectionStatus() {
      return this.$auth.hasPrivilegeAnyClient("5300");
    },

    canEditPrivileges () {
      const auth = this.$auth

      return auth.hasPrivilegeAnyClient("4000") ||
        (auth.hasPrivilegeAnyClient("4300") && this.group.isProtected == false) ||
        (auth.hasPrivilegeForClient(this.group.client, "4310") && this.group.isProtected == false)
    }
  },

  methods: {
    async handleDelete(confirmed: boolean) {
      this.showDeleteConfirm = false

      if (confirmed) {
        try {
          await this.$store.dispatch(ac.GROUP_DELETE, {group: this.group, force: this.forceDelete})
        }
        catch (error) {
          const e = error as {data?: string, errorCause?: string}
          const msg = `An error occurred while deleting the group. Message: ${e.data || e.errorCause || "(No message)"}`;

          notifications.warn(this.$store, msg)
        }
      }

      this.forceDelete = false
    },

    handleBufferSave(data: string) {
      this.saveInProgress = (data == "started") ? true : false;

      if (data == "succeeded") {
        //TODO: Update URL if needed.
      }
    },
  },
})
