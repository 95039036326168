
import { InsightClientListModel } from '@/models/insightClients/InsightClientListModel'
import Vue from 'vue'
import { RawLocation } from 'vue-router'
import { PropValidator } from 'vue/types/options'
import InsightClientAvatar from './InsightClientAvatar.vue'

export default Vue.extend({
  components: {
    InsightClientAvatar,
  },

  props: {
    clients: {
      type: Array,
      default: null,
    } as PropValidator<InsightClientListModel[]>,

    selectedIndex: {
      type: Number,
      default: 0,
    },

    minified: Boolean,
  },

  data () {
    return {
      sortedClients: [] as InsightClientListModel[]
    }
  },

  computed: {
    currentClient(): InsightClientListModel {
      return this.clients[this.selectedIndex]
    },
  },

  watch: {
    clients: {
      handler(): void {
        this.populateSortedClients()
      },
      deep: true,
    }
  },

  created() {
    this.populateSortedClients()
  },

  methods: {
    menuItemUrl(client: InsightClientListModel): RawLocation {
        const route = this.$route

        if (route.params.clientCode) {
          return {name: route.name ?? "", params: {clientCode: client.code}}
        } else {
          return this.$route.fullPath
        }
    },

    populateSortedClients() {
      this.sortedClients.length = 0

      const clonedClients = JSON.parse(JSON.stringify(this.clients)) as InsightClientListModel[]

      this.sortedClients.push(...clonedClients.sort((a,b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name < b.name) {
          return -1
        } else {
          return 0
        }
      }))
    }
  }
})
