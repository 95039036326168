
import { AjaxResponse } from 'vue-file-agent/types/src/lib/ajax-request'
import api from '@/util/api'
import { AppCenterFile } from '@/models/files/AppCenterFile'
import { FileRecord } from 'vue-file-agent'
import { GetAppCenterFileType } from '@/models/files/AppCenterFileType'
import notifications from '@/util/notifications'
import Vue from 'vue'
import VueFileAgentInterface from '@/models/core/componentInterfaces/VueFileAgentInterface'

export default Vue.extend({
  props: {
    client: {
      type: String,
      default: '',
    },

    makeFilesPublic: Boolean,

    single: Boolean,

    onlyImages: Boolean
  },

  data() {
    return {
      fileList: [] as FileRecord[],
      fileRecordsForUpload: [] as FileRecord[],
      uploadHeaders: {} as Record<string, string|undefined>
    }
  },

  computed: {
    acceptList(): string {
      if (this.onlyImages) {
        return ".jpg,.jpeg,.png"
      } else {
        return ".jpg,.jpeg,.pdf,.xlsx,.png,.docx,text/plain"
      }
    },

    uploadUrl(): string {
      return `${api.baseUrl}/${this.client}/files`;
    }
  },

  async mounted() {
    this.uploadHeaders = await api.getCustomHeaders();
  },

  methods: {
    customizeFormData(fileData: FileRecord): FormData {
      const formData = new FormData();
      formData.append('fileData', fileData.file);
      formData.append('name', fileData.file.name);
      formData.append('type', GetAppCenterFileType(fileData.file.type));
      formData.append('isPublic', this.makeFilesPublic ? "true" : "false");
      
      return formData;
    },

    filesSelected(fileRecordsNewlySelected: FileRecord[]): void {
      const validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);

      if (validFileRecords.length < fileRecordsNewlySelected.length) {
        const invalidFileRecords = fileRecordsNewlySelected.filter((fileRecord) => fileRecord.error);

        setTimeout(() => {
          invalidFileRecords.forEach(ir => {
            this.removeFailedFileRecord(ir);
          });

          const suffix = invalidFileRecords.length > 1 ? 's' : '';

          notifications.warn(
            this.$store,
            `${invalidFileRecords.length} file${suffix} had an invalid type. Please convert to an accepted file type and try again.`
          );
        }, 3000)
      }

      if (validFileRecords.length > 0) {
        this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
        this.uploadFiles();
      }
    },

    getFileAgent(): VueFileAgentInterface {
      return this.$refs.vueFileAgent as unknown as VueFileAgentInterface
    },

    onUpload(responses: AjaxResponse[]): void {
      responses.forEach(r => {
        if (r.status >= 200 && r.status < 300) {
          const file = r.data as AppCenterFile;

          this.$emit('file-uploaded', file);

          setTimeout(() => this.removeUploadedFile(file), 1000)
        }
      });
    },

    removeFailedFileRecord(file: FileRecord): void {
      const idx = this.fileList.findIndex(x => x.id == file.id);

      if (idx > -1) {
        this.fileList.splice(idx, 1);
      }
    },

    removeUploadedFile(file: AppCenterFile): void {
      const idx = this.fileList.findIndex(x => x.file.name == file.name);

      if (idx > -1) {
        this.fileList.splice(idx, 1);
      }
    },

    async uploadFiles(): Promise<void> {
      try {
        await this.getFileAgent().upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload, this.customizeFormData);
      } catch(e: unknown) {
        const err = e as {fileRecord: FileRecord}[];

        if (err[0]) {
          setTimeout(() => {
            this.removeFailedFileRecord(err[0].fileRecord);
            notifications.fail(this.$store, 'Your file was not uploaded.');
          }, 3000);
        }
      }

      this.fileRecordsForUpload = [];
    },
  }
})
