
import Vue from "vue"
import UserTile from "./UserTile.vue"
import { PropValidator } from 'vue/types/options'
import { InsightUser } from '@/models/users/InsightUser'

export default Vue.extend({
  components: {
      UserTile,
  },

  props: {
    users: {
      type: Array,
      default: null,
    } as PropValidator<InsightUser[]>,
  },
})
