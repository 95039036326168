import Vue from 'vue'
import Vuetify from 'vuetify'
// import 'vuetify/src/stylus/app.styl'

const theme = {
  themes: {
    light: {
      primary: "0081c8",
      accent: "62b6ff", //Primary lighten-2
      accent_alt: "fffb00", //Bright yellow
      info : "1e5515" //Dark green
    }
  }
}

Vue.use(Vuetify)

const opts = {
  theme,
  options: {
    customProperties: true
  }
}

export default new Vuetify(opts)
