
import * as ac from "../../store/actionNames"
import * as gt from "../../store/getterNames"
import notifications from "../../util/notifications"
import wordifier from '../../util/wordifier'
import Vue from "vue"
import { Application } from "@/models/features/Application"
import { RootState } from "@/store/RootState"
import { Feature } from "@/models/features/Feature"

export default Vue.extend({
  components: {
  },

  props: {
    show: Boolean,
  },

  data () {
    return {
      internalShow: false,
      formValid: false,
      application: '',
      feature: null as null|string,
      applicationRules: [
        (v?: string) => !!v || "Application is required",
      ],
      featureRules: [
        (v?: string) => !!v || "Feature is required",
      ],
      savingSubscription: false,
      loadingApplications: false,
      loadingFeatures: false,
    }
  },

  computed: {
    applicationList(): Application[] {
      const applications: Application[] = []
      applications.push(...(this.$store.state as RootState).features.applications)
      applications.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name == b.name) {
          return 0
        } else {
          return -1
        }
      })

      return applications
    },

    featureList(): Feature[] {
      if (this.application) {
        const features = []
        features.push(...this.$store.getters[gt.FEATURES_FOR_APPLICATION](this.application))
        features.sort((a, b) => {
          if (a.name > b.name) {
            return 1
          } else if (a.name == b.name) {
            return 0
          } else {
            return -1
          }
        })

        features.forEach(f => f.name = wordifier.fromCamelCase(f.name))

        return features
      } else {
        return []
      }
    },

    clientCode(): string {
      return this.$store.getters[gt.CLIENT_CURRENT].code
    },
  },

  watch: {
    show() {
      this.internalShow = this.show;
    }
  },

  created () {
    this.loadApplications()
  },

  methods: {
    async dismiss (mode: "yes"|"no" ): Promise<void> {
      if (mode == 'yes') {
        await this.saveSubscription()
      }

      this.internalShow = false;
      this.$emit('update:show', false)

      this.application = '' 
      this.feature = null

      this.getForm().resetValidation()
    },

    async saveSubscription(): Promise<void> {
      this.savingSubscription = true

      const data = {
        client: this.clientCode,
        feature: {
          id: this.feature
        }
      }

      try {
        await this.$store.dispatch(ac.SUBSCRIPTION_CREATE, data)
      } catch (e) {
        notifications.fail(this.$store, "Failed to create subscription!")
      }

      this.savingSubscription = false
    },

    async loadApplications(): Promise<void> {
      this.loadingApplications = true

      try {
        await this.$store.dispatch(ac.FEATURE_FETCH_ALL_APPLICATIONS)
      } catch (e) {
        notifications.fail(this.$store, "Failed to load applications!")
      }

      this.loadingApplications = false
    },

    async loadFeatures(): Promise<void> {
      this.feature = null

      if (this.application) {
        this.loadingFeatures = true

        try {
          await this.$store.dispatch(ac.FEATURE_FETCH_ALL_FEATURES, this.application)
        } catch (e) {
          notifications.fail(this.$store, "Failed to load features!")
        }

        this.loadingFeatures = false
      }
    },

    getForm(): {resetValidation: () => void} {
      return this.$refs.newSubscriptionForm as unknown as {resetValidation: () => void}
    }
  }
})
