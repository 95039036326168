
import { isPhone, requiredIf, Validator } from '../../util/validators'

export default {
  methods: {
    getRules(required: boolean, label: string): Validator[] {
      return [
        requiredIf(() => required, () => label),
        isPhone(() => label)
      ]
    }
  }
}
