
import Vue from "vue"
import * as gt from "../../store/getterNames"
import * as mt from "../../store/mutationNames"
import * as ac from "../../store/actionNames"
import { SubscriptionUpdateEvent } from "../../models/subscriptions/SubscriptionUpdateEvent";
import notifications from "../../util/notifications"
import SubscriptionList from "./SubscriptionList.vue"
import SubscriptionSetup from "./SubscriptionSetup.vue"
import NewSubscription from "./NewSubscription.vue"
import { Subscription } from "@/models/subscriptions/Subscription"

const SUBSCRIPTION_TITLE = "Subscriptions"

export default Vue.extend({
  components: {
    SubscriptionList,
    NewSubscription,
    SubscriptionSetup,
  },

  props: {
    clientCode: {
      type: String,
      default: null,
    },
  },

  data () {
    return {
      showNewSubscriptionDlg: false,
      loadingSubscriptions: false,
      showDeletedSubscriptions: false,
      showSetup: false,
      subscriptionForSetup: null as null|Subscription,
      updatingSubscriptionId: '',
    }
  },

  computed: {
    subscriptions(): Subscription[] {
      return this.$store.getters[gt.SUBSCRIPTIONS_FOR_CURRENT_CLIENT]
    },

    clientName(): string {
      return this.$store.getters[gt.CLIENT_CURRENT].name
    },

    canViewSubscriptions(): boolean {
      return this.$auth.hasPrivilegeAnyClient("8200") ||
        this.$auth.hasPrivilegeForClient(this.clientCode, "8210")
    },

    canAddSubscriptions(): boolean {
      return this.$auth.hasPrivilegeAnyClient("8100") ||
        this.$auth.hasPrivilegeForClient(this.clientCode, "8110")
    },

    canDeleteSubscriptions(): boolean {
      return this.$auth.hasPrivilegeAnyClient("8300") ||
        this.$auth.hasPrivilegeForClient(this.clientCode, "8310")
    },

    subscriptionSetupName(): string {
      if (this.subscriptionForSetup == null) return '';

      if (this.subscriptionForSetup.feature.id == '1001000') { //Quotes
        return 'quotes'
      } else if (this.subscriptionForSetup.feature.id == '1002000') { //Work Orders
        return 'workOrders'
      } else if (this.subscriptionForSetup.feature.id == '1003000') { //Tickets
        return 'tickets'
      } else {
        return this.subscriptionForSetup.feature.name
      }
    },

    
  },

  created () {
    this.$store.commit(mt.CORE_SET_PAGE_TITLE, SUBSCRIPTION_TITLE)

    this.loadSubscriptions()
  },

  methods: {
    async loadSubscriptions() {
      this.loadingSubscriptions = true

      try {
        await this.$store.dispatch(ac.SUBSCRIPTION_FETCH_ALL)
      } catch (e) {
        notifications.fail(this.$store, "Failed to load subscriptions!")
      }

      this.loadingSubscriptions = false
    },

    async handleSetupRequested(subscription: Subscription): Promise<void> {
      this.subscriptionForSetup = subscription;
      
      await this.$nextTick();
      
      this.showSetup = true;
    },

    async handleUpdateSubscription(evt: SubscriptionUpdateEvent): Promise<void> {
      this.updatingSubscriptionId = this.subscriptionForSetup?.id ?? '';

      const subscription = Object.assign({}, this.subscriptionForSetup);
      subscription.provisioningComplete = evt.provisioningComplete;
      subscription.currentProvisioningStep = evt.currentProvisioningStep;

      try {
        await this.$store.dispatch(ac.SUBSCRIPTION_UPDATE, subscription);
      } catch (error) {
        const e = error as {data?: string, errorCause?: string};
        notifications.fail(this.$store, `Failed to update subscription status. Message: ${e.data || e.errorCause || "(No message)"}`);
      }

      this.subscriptionForSetup = null;
      this.updatingSubscriptionId = '';
    },

    async updateShowDeletedSubscriptions() {
      this.$store.commit(mt.SUBSCRIPTION_SET_MANAGE_DELETED_SUBSCRIPTIONS, this.showDeletedSubscriptions)

      await this.loadSubscriptions()
    }
  },
})
