import { CORE_SET_CURRENT_USER_ID } from '@/store/mutationNames'
import store from '@/store/store'
import { RealtimeService } from '@/util/realtimeUpdates'
import { AuthConfig, InsightApplication, InsightAuth } from 'insight-auth'
import environment from "../util/environment"

export const auth = new InsightAuth(new AuthConfig(
    environment,
    InsightApplication.AppCenter,
    window.location.origin + '/login-redirect'
  ),
  RealtimeService.getSecurityCacheControlEventSource(),
  [(evt) => store.commit(CORE_SET_CURRENT_USER_ID, evt.userId)]
)