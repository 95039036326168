
import Vue from "vue"

export default Vue.extend({
  props: {
    show: Boolean,

    yesText: {
      type: String,
      default: 'Yes',
    },

    noText: {
      type: String,
      default: 'No',
    },

    confirmText: {
      type: String,
      default: 'random',
    }
  },

  data() {
    return {
      internalShow: false,
      internalConfirmText: "",
      isValid: false,
      userText: "",
      matchRules: [] as Array<(v?: string)=> string|boolean>
    }
  },

  watch: {
    show(): void {
      this.internalShow = this.show;
      this.matchRules = [
        (v) => this.textMatches(v)
      ]

      if (this.show == true && (this.confirmText == "random" || this.confirmText == "")) {
        this.internalConfirmText = this.getRandomConfirmText()
        this.userText = ""

        if (this.$refs.confirmForm) {
          this.getForm().resetValidation()
        }
      }
    }
  },

  methods: {
    dismiss(mode: "yes"|"no" ) {
      this.getForm().validate()

      if (!this.isValid && mode == "yes") {
        return;
      }
      this.internalShow = false;
      this.$emit('update:show', false)
      this.$emit("response", mode == "yes" ? true : false)
    },

    getRandomConfirmText () {
      var choices = [
        "I understand",
        "I'm serious",
        "Got it",
        "I'm sure",
        "Go ahead",
        "I know",
      ]

      const idx = Math.round(Math.random() * 6)

      return choices[idx]
    },

    getForm(): {validate: () => void; resetValidation: () => void} {
      return this.$refs.confirmForm as unknown as {validate: () => void; resetValidation: () => void};
    },

    textMatches(v?: string): boolean|string {
      return (v||"").toLowerCase() == (this.internalConfirmText ||"").toLowerCase() && this.internalConfirmText > "" || "Text doesn't match!"
    }
  }
})
