
import { InsightClientListModel } from "@/models/insightClients/InsightClientListModel"
import Vue from "vue"
import { PropValidator } from "vue/types/options"

export default Vue.extend({
  props: {
    clients: {
      type: Array,
      default: null,
    } as PropValidator<InsightClientListModel[]>,

    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {}
  },

  methods: {
    handleClientSelection (payload: InsightClientListModel): void {
      this.$emit("input", payload.code)
    }
  },
})
