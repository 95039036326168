import api from '../../util/api'
import * as mt from "../mutationNames"
import * as ac from "../actionNames"
import * as gt from "../getterNames"
import { Subscription } from '../../models/subscriptions/Subscription'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '../RootState'
import { ApiRequestParams } from '../../models/core/ApiRequestParams'


const _findIndex = (arr: Subscription[], spec: {id: string}) => {
    return arr.findIndex(u => {return u.id == spec.id})
}

export class SubscriptionState {
    public subscriptions: Subscription[] = [];
    manageDeletedSubscriptions = false;
}

const getters: GetterTree<SubscriptionState, RootState> = {
    [gt.SUBSCRIPTIONS_FOR_CURRENT_CLIENT] (state, getters) {
        const clientCode = (getters[gt.CLIENT_CURRENT] || {}).code

        return state.subscriptions.filter(s => s.client == clientCode)
    },
}

const mutations: MutationTree<SubscriptionState> = {
    [mt.SUBSCRIPTION_REPLACE_ALL_FOR_CLIENT] (state, subscriptionData: {client: string, subscriptions: Subscription[]}) {
        const filteredList = state.subscriptions.filter(s => s.client != subscriptionData.client)
        const safeNewSubscriptions = subscriptionData.subscriptions.filter(s => s.client == subscriptionData.client)

        filteredList.push(...safeNewSubscriptions)

        state.subscriptions = filteredList
    },

    [mt.SUBSCRIPTION_ADD_OR_UPDATE] (state, subscription) {
        const idx = _findIndex(state.subscriptions, subscription)

        if (idx > -1) {
            state.subscriptions.splice(idx, 1, subscription)
        } else {
            state.subscriptions.push(subscription)
        }
    },

    [mt.SUBSCRIPTION_PURGE] (state, spec) {
        const mainIdx = _findIndex(state.subscriptions, spec)

        if (mainIdx > -1) {
            state.subscriptions.splice(mainIdx, 1)
        }
    },

    [mt.SUBSCRIPTION_SET_MANAGE_DELETED_SUBSCRIPTIONS] (state, showDeletedItems) {
        state.manageDeletedSubscriptions = showDeletedItems
    }
}

const actions: ActionTree<SubscriptionState, RootState> = {
    async [ac.SUBSCRIPTION_FETCH_ALL] ({commit, getters, state}, requestedClient) {

        const client = requestedClient || getters[gt.CLIENT_CURRENT] || {}

        const params: ApiRequestParams = [["includeDeleted", `${state.manageDeletedSubscriptions}`]]

        const response = await api.get("insightClients/" + client.code + "/subscriptions", params)

        if (!response) return Promise.reject();

        if (response.error) {
            return Promise.reject(response);
        }

        const subscriptions = response.data

        commit(mt.SUBSCRIPTION_REPLACE_ALL_FOR_CLIENT, { client: client.code, subscriptions })
    },

    async [ac.SUBSCRIPTION_CREATE] ({commit}, data) {
        const url = "insightClients/" + data.client + "/subscriptions"

        const response = await api.create(url, data)

        if (!response) return Promise.reject();

        if (response.error) {
            return Promise.reject(response)
        }

        const subscription = response.data

        commit(mt.SUBSCRIPTION_ADD_OR_UPDATE, subscription)
    },

    async [ac.SUBSCRIPTION_UPDATE] ({commit}, data: Subscription) {
        const url = "insightClients/" + data.client + "/subscriptions/" + data.id

        const response = await api.update(url, data)

        if (!response) return Promise.reject();

        if (response.error) {
            return Promise.reject(response)
        }

        const subscription = response.data as Subscription;

        commit(mt.SUBSCRIPTION_ADD_OR_UPDATE, subscription)
    },

    async [ac.SUBSCRIPTION_DELETE] ({commit}, spec) {
        const url = "insightClients/" + spec.client + "/subscriptions/" + spec.id

        const response = await api.delete(url)

        if (!response) return Promise.reject();

        if (response.error) {
            return Promise.reject(response)
        }

        commit(mt.SUBSCRIPTION_PURGE, spec);
    },

    // async [ac.USER_UNDELETE] ({commit}, spec) {
    //     const url = "users/" + spec.id + "/undelete"

    //     const response = await api.update(url)

    //     if (response.error) {
    //         return Promise.reject(response)
    //     }

    //     const user = response.data

    //     commit(mt.USER_ADD_OR_REPLACE_OPEN_USER, user);
    // },
}

export default {
  actions,
  mutations,
  state: new SubscriptionState(),
  getters
}