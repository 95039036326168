
import Vue from "vue"
import { mapState, mapActions } from 'vuex'
import InsightClientTile from "./InsightClientTile.vue"
import NewInsightClient from "./NewInsightClient.vue"
import * as ac from "../../store/actionNames"
import * as gt from "../../store/getterNames"
import { InsightClient } from "@/models/insightClients/InsightClient"
import { RootState } from "@/store/RootState"
import InsightClientEditor from "./InsightClientEditor.vue"
import { InsightClientListModel } from "@/models/insightClients/InsightClientListModel"
import api from "@/util/api"
import notifications from "@/util/notifications"

export default Vue.extend({
  components : {
    InsightClientTile,
    NewInsightClient,
    InsightClientEditor,
  },

  data: () => ({
    clientForEdit: null as InsightClient|null,
    loadingClients: false,
    loadingClientForEdit: '',
    showNewClientDlg: false,
    showClientEditor: false,
  }),

  computed: {
    ...mapState({
      clients: (state) => (state as RootState).insightClients.clients,
    }),

    selectedClient(): InsightClientListModel[] {
      var currentClient = this.$store.getters[gt.CLIENT_CURRENT] as InsightClientListModel

      if (currentClient) {
        return [currentClient]
      } else {
        return []
      }
    },

    canAddClient(): boolean {
      return this.$auth.hasPrivilegeAnyClient("2300")
    }
  },

  created() {
    if (this.clients.length == 0) {
      this.loadClients()
    }
  },

  methods: {
    ...mapActions([ac.CLIENT_FETCH_ALL]),

    async loadClients(): Promise<void> {
      this.loadingClients = true

      await this[ac.CLIENT_FETCH_ALL]()

      this.loadingClients = false
    },

    async editClient(clientCode: string): Promise<void> {
      this.loadingClientForEdit = clientCode;

      const apiResponse = await api.get("insightClients/" + clientCode)

      if (apiResponse) {
        if (apiResponse.error) {
          return Promise.reject(apiResponse);
        }

        this.clientForEdit = apiResponse.data as InsightClient;
        this.showClientEditor = true;
      } else {
        notifications.fail(this.$store, "There was an error loading this Insight Client");
      }

      this.loadingClientForEdit = "";
    }
  },
})
