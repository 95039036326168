
import { InsightClientListModel } from "@/models/insightClients/InsightClientListModel"
import Vue from "vue"
import { PropValidator } from "vue/types/options"
import InsightClientList from "./InsightClientList.vue"

export default Vue.extend({
  components: {
    InsightClientList,
  },

  props: {
    show: Boolean,

    clients: {
      type: Array,
      default: null,
    } as PropValidator<InsightClientListModel[]>,
  },

  data () {
    return {
      internalShow: false,
      selectedClient: "",
    }
  },

  computed: {
    sortedClients(): InsightClientListModel[] {
      const clients = this.clients.filter(x => x.code > '');

      clients.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name == b.name) {
          return 0
        } else {
          return -1
        }
      })

      return clients
    },
  },

  watch: {
    show () {
      this.internalShow = this.show
      this.selectedClient = ""
    }
  },

  methods: {
    dismiss (mode: "yes"|"no"): void {
      this.internalShow = false;
      this.$emit('update:show', false)

      if (mode == "yes") {
        this.$emit("client-selected", this.clients.find(x => x.code == this.selectedClient))
      }    
    }
  },
})
