
import { GroupListModel } from "@/models/groups/GroupListModel"
import Vue from "vue"
import { PropValidator } from "vue/types/options"

export default Vue.extend({
  props: {
    groups: {
      type:Array,
      default: null,
    } as PropValidator<GroupListModel[]>,

    value: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
    }
  },

  methods: {
    handleSelection (payload: never) {
      this.$emit("input", payload)
    }
  },
})
