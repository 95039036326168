
import * as gt from "../../store/getterNames"
import * as ac from "../../store/actionNames"
import Vue from 'vue'
import GroupListCompact from "./GroupListCompact.vue"
import { PropValidator } from "vue/types/options"
import { GroupListModel } from "@/models/groups/GroupListModel"

const DATA_INITIAL = "inital"
const DATA_LOADING = "loading" 
const DATA_LOADED = "loaded" 
const DATA_FAILED = "failed"

export default Vue.extend({  
  components: {
    GroupListCompact,
  },

  props: {
    show: Boolean,

    excludedGroups: {
      type: Array,
      default: null,
    } as PropValidator<GroupListModel[]>,

    client: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      internalShow: false,
      selectedGroup: -1,
      loadState : DATA_INITIAL,
    }
  },

  computed: {
    groups(): GroupListModel[] {
      const allGroups = this.$store.getters[gt.GROUPS_FOR_CLIENT](this.client) as GroupListModel[]
      
      var filteredGroups = allGroups.filter(g => !this.excludedGroups.find(x => x.slug == g.slug))

      if (this.hideProtectedGroups) {
        filteredGroups = filteredGroups.filter(g => !g.isProtected)
      }

      return filteredGroups
    },

    sortedGroups(): GroupListModel[] {
      const groups = []
      groups.push(...this.groups)
      groups.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name == b.name) {
          return 0
        } else {
          return -1
        }
      })

      return groups
    },

    hideProtectedGroups(): boolean {
      return !this.$auth.hasPrivilegeAnyClient("5200")
    },
  },

  watch: {
    show() {
      this.internalShow = this.show
      this.selectedGroup = -1

      if (this.loadState == DATA_INITIAL) {
        this.loadGroupsForClient()
      }
    },

    client() {
      this.loadState = DATA_INITIAL

      if (this.show) {
        this.loadGroupsForClient()
      }
    }
  },

  methods: {
    dismiss (mode: "yes"|"no" ) {
      this.internalShow = false;
      this.$emit('update:show', false)

      if (mode == "yes") {
        this.$emit("group-selected", this.sortedGroups[this.selectedGroup])
      } 
    },

    async loadGroupsForClient () {
      this.loadState = DATA_LOADING

      try {
        await this.$store.dispatch(ac.GROUP_FETCH_ALL, {code: this.client})
        
        this.loadState = DATA_LOADED
      } catch (e) {
        this.loadState = DATA_FAILED
      }
    },
  },
})
