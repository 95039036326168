import { Store } from "vuex";
import * as mt from "../store/mutationNames"

const emit = (store: Store<unknown>, text: string, color: string, icon:string): void => {
    store.commit(mt.CORE_ADD_NOTIFICATION, {text, color, icon})
}

const inform = (store: Store<unknown>, text:string) => {
    emit(store, text, "info", "mdi-information");
}

const succeed = (store: Store<unknown>, text:string) => {
    emit(store, text, "success", "mdi-check");
}

const warn = (store: Store<unknown>, text:string) => {
    emit(store, text, "warning", "mdi-alert");
}

const fail = (store: Store<unknown>, text:string) => {
    emit(store, text, "error", "mdi-alert-octagon")
}

export default {
    emit,
    inform,
    succeed,
    warn,
    fail,
}