import { AppConfig } from "@/models/core/AppConfig";
import api from "@/util/api";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { InsightNotification } from "../../models/core/InsightNotification";
import { CORE_FETCH_APP_CONFIG } from "../actionNames";
import * as gt from "../getterNames"
import * as mt from "../mutationNames"
import { RootState } from "../RootState";

export class CoreState {
  sideNav = {
    visible: true,
    minified: true
  };

  notifications: InsightNotification[] = [];
  appDataLoaded:"yes"|"no"|"failed" = "no";
  appConfig: AppConfig = {
    realTimeKey: ""
  };
  pageTitle = "";

  currentUserId = "";
}

const getters: GetterTree<CoreState, RootState> = {
  [gt.CORE_NOTIFICATION_COUNT] (state) {
    return state.notifications.length
  },

  [gt.CORE_NEXT_NOTIFICATION] (state) {
    if (state.notifications.length > 0) {
      return state.notifications[0]
    } else {
      return null
    }
  }
}

const mutations: MutationTree<CoreState> = {
  [mt.CORE_SET_APP_DATA_LOAD_STATE] (state: CoreState, loadState: "yes"|"no"|"failed") {
    if (loadState != "yes" && loadState != "no" && loadState != "failed") {
      throw new Error("Invalid load state argument: " + loadState)
    }

    state.appDataLoaded = loadState
  },
  
  [mt.CORE_TOGGLE_SIDENAV_MINI] (state: CoreState) {
    state.sideNav.minified = !state.sideNav.minified;
  },

  [mt.CORE_ADD_NOTIFICATION] (state: CoreState, newNotification: InsightNotification) {
    if (newNotification.text) {
      newNotification.color = newNotification.color || 'info'
      newNotification.icon = newNotification.icon || 'mdi-information'

      state.notifications.push(newNotification)
    } else {
      throw new Error('Notification text cannot be blank')
    }
  },

  [mt.CORE_REMOVE_NOTIFICATION] (state, index: number) {
    state.notifications.splice(index, 1)
  },

  [mt.CORE_SET_PAGE_TITLE] (state, newTitle: string) {
    state.pageTitle = newTitle
  },

  [mt.CORE_SET_CURRENT_USER_ID] (state, newId: string) {
    state.currentUserId = newId;
  },

  [mt.CORE_SET_APP_CONFIG] (state, appConfig: AppConfig) {
    state.appConfig = appConfig;
  }
}

const actions: ActionTree<CoreState, RootState> = {
  async [CORE_FETCH_APP_CONFIG] ({commit}): Promise<void> {
    const response = await api.get(`appConfig`)

    if (!response) return Promise.reject("No resposne from API");

    if (response.error) {
      return Promise.reject(response);
    }

    const cfg = response.data as AppConfig;

    commit(mt.CORE_SET_APP_CONFIG, cfg);
  },
}

export default {
  actions,
  mutations,
  state: new CoreState(),
  getters
}
