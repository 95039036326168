export type ValidatorArgFetcher<T> = () => T

export type Validator = (v: string) => true|string;

export type ValidatorFactory<T> = (arg: T|ValidatorArgFetcher<T>, label: string|ValidatorArgFetcher<string>)
  => Validator;

export type FixedValidatorFactory = (label: string|ValidatorArgFetcher<string>) => Validator;

export type GenericValidator<T> = (v: T) => true|string;

export type GenericValidatorFactory<Tvalue, Targ> = (arg: Targ|ValidatorArgFetcher<Targ>, label: string|ValidatorArgFetcher<string>)
  => GenericValidator<Tvalue>;

export type FixedGenericValidatorFactory<T> = (label: string|ValidatorArgFetcher<string>) => GenericValidator<T>;

function _resolveArg<T>(arg: T|ValidatorArgFetcher<T>): T {
  return typeof arg === 'function' ? (arg as ValidatorArgFetcher<T>)() : arg
} 

export const requiredIf: ValidatorFactory<boolean> = (condition, label) => {
  return (v): true|string => {
    const required = !!_resolveArg(condition)
    const prefix = _resolveArg(label)

    return !required || !!v || (prefix + ' is required')
  }
}

export const maxLength: ValidatorFactory<number> = (max, label) => {
  return (v): true|string => {
    const limit = _resolveArg(max)
    const prefix = _resolveArg(label)

    return (v || '').length <= limit || (prefix + ' is too long')
  }
}

export const isEmail: FixedValidatorFactory = (label) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return (v): true|string => {
    const prefix = _resolveArg(label)

    return !v || emailRegex.test(v) || (prefix + ' must be a valid email address')
  }
}

export const isPhone: FixedValidatorFactory = (label) => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

  return (v): true|string => {
    const prefix = _resolveArg(label)

    return !v || phoneRegex.test(v) || (prefix + ' must be a valid phone number')
  }
}

export const arrayMinLength: GenericValidatorFactory<unknown[], number> = (min, label) => {
  return (v): true|string => {
    const limit = _resolveArg(min)
    const labelText = _resolveArg(label)

    return (v ?? []).length >= limit || (`Too few ${labelText}. Minimum ${limit}`)
  }
}
