
import Vue from 'vue'
import * as ac from "../../store/actionNames"
import notifications from "../../util/notifications"
import NameField from "../common/NameField.vue"
import EmailField from "../common/EmailField.vue"
import PopupGroupChooser from "../groups/PopupGroupChooser.vue"
import { RootState } from '@/store/RootState'
import { GroupListModel } from '@/models/groups/GroupListModel'
import { InsightUserInvitation } from '@/models/users/InsightUserInvitation'
import { InsightClientListModel } from '@/models/insightClients/InsightClientListModel'

export default Vue.extend({
  components: {
    EmailField,
    NameField,
    PopupGroupChooser,
  },

  props: {
    show: Boolean,
  },

  data() {
    return {
      internalShow: false,
      formValid: false,
      name: '',
      email: '',
      client: '',
      group: new GroupListModel(),
      clientRules: [
        (v: string) => !!v || "Client is required",
      ],
      showGroupChooser: false,
      sendingInvite: false
    }
  },

  computed: {
    clientList(): InsightClientListModel[] {
      const clients: InsightClientListModel[] = []
      clients.push(...(this.$store.state as RootState).insightClients.clients)
      clients.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name == b.name) {
          return 0
        } else {
          return -1
        }
      })

      return clients
    },

    canAssignGroups(): boolean {
      const auth = this.$auth

      return auth.hasPrivilegeAnyClient("5210") ||
        auth.hasPrivilegeForClient(this.client, "5220")
    }
  },

  watch: {
    show(): void {
      this.internalShow = this.show;
    }
  },

  methods: {
    assignToGroup(group: GroupListModel): void {
      this.group = group
    },

    async dismiss(mode: 'yes'|'no' ): Promise<void> {
      if (mode == 'yes') {
        await this.inviteUser()
      }

      this.internalShow = false;
      this.$emit('update:show', false)

      this.name = ''
      this.email = ''
      this.client = '' 
      this.group = new GroupListModel() 

      this.getForm().resetValidation()
    },

    getForm(): {resetValidation: () => void} {
      return this.$refs.inviteUserForm as unknown as {resetValidation: () => void}
    },

    async inviteUser(): Promise<void> {
      this.sendingInvite = true

      const data = new InsightUserInvitation(
        this.name,
        this.email,
        [new InsightClientListModel(this.client)],
        this.group.name > '' ? [this.group] : []
      )

      try {
        await this.$store.dispatch(ac.USER_INVITE, data)

        notifications.succeed(this.$store, "The invitation for " + this.name + " has been sent!")
      } catch (e) {
        notifications.fail(this.$store, "Failed to send invitation! please try again")
      }

      this.sendingInvite = false
    },
  },
})
