
import Vue from "vue"
import wordifier from "../../util/wordifier"
import notifications from "../../util/notifications"
import * as ac from "../../store/actionNames"
import ConfirmDlg from "../common/ConfirmDlg.vue"
import { PropValidator } from "vue/types/options"
import { Subscription } from "@/models/subscriptions/Subscription"
import { CLIENT_CURRENT } from "@/store/getterNames"

export default Vue.extend({

  components: {
    ConfirmDlg,
  },

  props: {
    subscription: {
      type: Object,
      default: null,
    } as PropValidator<Subscription>,

    updatingSubscription: Boolean,
  },

  data() {
    return {
      showDeleteConfirmDlg: false,
      showLoader: false,
    }
  },

  computed: {
    clientCode(): string {
      return this.$store.getters[CLIENT_CURRENT].code
    },

    displayName(): string {
      return wordifier.fromCamelCase(this.subscription.feature.name)
    },

    canDeleteSubscriptions(): boolean {
      return this.$auth.hasPrivilegeAnyClient("8300") ||
        this.$auth.hasPrivilegeForClient(this.clientCode, "8310")
    },

    isDeleted(): boolean {
      return this.subscription.deletedBy > ""
    },

    tileIcon(): string {
      if (this.isDeleted) {
        return "mdi-cancel"
      } else {
        return "mdi-receipt"
      }
    },

    tileIconColor () {
      if (this.isDeleted) {
        return "error"
      } else {
        return ""
      }
    },
  },

  methods: {
    async deleteSubscription (confirmed: boolean) {
      if (confirmed) {
        this.showLoader = true

        try {
          await this.$store.dispatch(ac.SUBSCRIPTION_DELETE, this.subscription)
        } catch (e) {
          notifications.fail(this.$store, "Failed to delete subscription!")
        }

        this.showLoader = false
      }
    },

    requestSetup() {
      this.$emit('setup-requested', this.subscription)
    },
  },
})
