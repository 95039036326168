
import Vue from "vue"

export default Vue.extend({
  props: {
    show: Boolean,

    yesText: {
      type: String,
      default: 'Yes'
    },

    noText: {
      type: String,
      default: 'No'
    }
  },

  data() {
    return {
      internalShow: false
    }
  },

  watch: {
    show(): void {
      this.internalShow = this.show;
    }
  },

  methods: {
    dismiss (mode: "yes"|"no" ): void {
      this.internalShow = false;
      this.$emit('update:show', false)
      this.$emit("response", mode == "yes" ? true : false)
    }
  }
})
