
  import { mapState } from 'vuex'
  import * as mt from '../../store/mutationNames'
  import * as gt from '../../store/getterNames'
  import environment from '../../util/environment'
  import InsightClientSelector from "../insightClients/InsightClientSelector.vue"
  import Vue from 'vue'
import { RootState } from '@/store/RootState'


  export default Vue.extend({
    components: {
      InsightClientSelector,
    },
    
    data() {
      return {
        loggingOut : false
      }
    },

    computed: {
      ...mapState({
        sideNav: state => (state as RootState).core.sideNav,
        clientList: state => (state as RootState).insightClients.clients,
        currentClientIdx: state => (state as RootState).insightClients.selectedClientIndex,
      }),

      currentClientCode(): string {
        return (this.$store.getters[gt.CLIENT_CURRENT] || {}).code || ""
      },

      isLoggedIn(): boolean {
        return (this.$store.state as RootState).core.currentUserId > ""
      },

      footerTextColor(): string {
        if (environment == 'dev') {
          return "warning--text"
        } else if (environment == 'staging') {
          return "error--text"
        } else {
          return "white--text"
        }
      },

      canViewGroups(): boolean {
        return this.$auth.hasPrivilegeAnyClient("5500") ||
          this.$auth.hasPrivilegeForClient(this.currentClientCode, "5510")
      },

      canViewUsers(): boolean {
        return this.$auth.hasPrivilegeAnyClient("6100") ||
          this.$auth.hasPrivilegeForClient(this.currentClientCode, "6110")
      },

      canViewSubscriptions(): boolean {
        return this.$auth.hasPrivilegeAnyClient("8200") ||
          this.$auth.hasPrivilegeForClient(this.currentClientCode, "8210")
      }
    },

    methods: {
      toggleSideNav () {
        this.$store.commit(mt.CORE_TOGGLE_SIDENAV_MINI)
      },

      logMeOut () {
        this.loggingOut = true
        this.$auth.logout()
      }
    },
  });
