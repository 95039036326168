
import Vue from 'vue';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    required: Boolean,
  },

  data () {
    return {
      rules: [
        (v?: string) => !this.required || !!v || (this.label + " is required"),
        (v?: string) => (v || "").length <= 150 || (this.label + " is too long"),
        (v?: string) => !v || emailRegex.test(v ?? "") || (this.label + " must be avalid email address")
      ]
    }
  },

  methods: {
    handleInput(payload: never) {
      this.$emit("input", payload)
    }
  },
})
