import * as mt from "../mutationNames"
import * as ac from "../actionNames"
import api from "../../util/api"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import { RootState } from "../RootState"
import { Privilege } from "../../models/privileges/Privilege"

const privilegeSortFunction = (a: Privilege, b: Privilege): number => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  } else if (a.name.toLowerCase() == b.name.toLowerCase()) {
    return 0;
  } else {
    return 1;
  }
}

const _buildPrivilegeTree = (privilegeList: Privilege[]) => {
  const safeList = JSON.parse(JSON.stringify(privilegeList)) as Privilege[]
  const privilegeTree: Privilege[] = [];
  const privilegeHash = safeList.reduce((hash: Record<string, Privilege>, item) => {
    hash[item.id] = item 
    return hash
  }, {})

  safeList.forEach(privilege => {
    if (privilege.parent == "0" || privilege.parent == '') {
      privilegeTree.push(privilege);
    } else {
      const parent = privilegeHash[privilege.parent]

      if (!parent.children) parent.children = []

      parent.children.push(privilege)
    }
  });



  safeList.forEach(privilege => {
    if (privilege.children) {
      privilege.children.sort(privilegeSortFunction)
    }
  })

  privilegeTree.sort(privilegeSortFunction)

  return privilegeTree
}

export class PrivilegeState {
  privileges: Privilege[] = [];
  privilegeTree: Privilege[] = [];
}

const getters: GetterTree<PrivilegeState, RootState> = {
 
}

const mutations: MutationTree<PrivilegeState> = {
  [mt.PRIVILEGE_REPLACE_LIST] (state, privilegeList) {
    state.privileges = privilegeList
    state.privilegeTree = _buildPrivilegeTree(privilegeList)
  },
}

const actions: ActionTree<PrivilegeState, RootState> = {
  async [ac.PRIVILEGE_FETCH_ALL] ({commit}) {
    const privilegeResponse = await api.get("/privileges")

    if (!privilegeResponse) return;
    
    if (privilegeResponse.error) {
      return Promise.reject(privilegeResponse);
    }

    const privileges = privilegeResponse.data

    commit(mt.PRIVILEGE_REPLACE_LIST, privileges)
  }
}

export default {
 actions,
 mutations,
 state: new PrivilegeState(),
 getters
}
