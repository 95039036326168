
import { GroupListModel } from "@/models/groups/GroupListModel"
import Vue from "vue"
import { PropValidator } from "vue/types/options"
import GroupTile from "./GroupTile.vue"

export default Vue.extend({
  components: {
    GroupTile,
  },

  props: {
    groups: {
      type: Array,
      default: null,
    } as PropValidator<GroupListModel[]>,
  },
})
