
import Vue from "vue"
import * as ac from "../../store/actionNames"
import notifications from "../../util/notifications"
import UserProfileClientItem from "./UserProfileClientItem.vue"
import UserProfileGroupItem from "./UserProfileGroupItem.vue"
import PopupClientChooser from "../insightClients/PopupClientChooser.vue"
import PopupGroupChooser from "../groups/PopupGroupChooser.vue"
import DoubleConfirmDlg from "../common/DoubleConfirmDlg.vue"
import { PropValidator } from "vue/types/options"
import { InsightUser } from "@/models/users/InsightUser"
import { InsightClientListModel } from "@/models/insightClients/InsightClientListModel"
import { GroupListModel } from "@/models/groups/GroupListModel"
import { RootState } from "@/store/RootState"

export default Vue.extend({
  components: {
    UserProfileClientItem,
    UserProfileGroupItem,
    PopupClientChooser,
    DoubleConfirmDlg,
    PopupGroupChooser,
  },

  props: {
    clientCode: {
      type: String,
      default: null,
    },

    user: {
      type: Object,
      default: null,
    } as PropValidator<InsightUser>,
  },

  data() {
    return {
      selectedGroupIdx: 0,
      selectedClient: new InsightClientListModel(),
      showClientChooser: false,
      showGroupChooser: false,
      showDeleteConfirm: false,
      showOverlay: false,
      isSelf: false,
      unlinkingClientCode: '',
    }
  },

  computed: {
    isProtectedUser(): boolean {
      return !!this.user.groups.find(g => g.client == this.clientCode && g.isProtected == true)
    },

    canViewProfile(): boolean {
      const auth = this.$auth

      return (auth.hasPrivilegeAnyClient("6200") ||
        (this.user.clients.find(x => x.code == this.clientCode) != undefined &&
        auth.hasPrivilegeForClient(this.clientCode, "6210")))
    },

    canAssignGroups(): boolean {
      const auth = this.$auth

      return (auth.hasPrivilegeAnyClient("5200") ||
        (auth.hasPrivilegeAnyClient("5210") && !this.isProtectedUser) ||
        (auth.hasPrivilegeForClient(this.selectedClient.code, "5220") && !this.isProtectedUser)) &&
        !this.userIsDeleted
    },

    canLinkToClients(): boolean {
      return this.$auth.hasPrivilegeAnyClient("6400") && !this.userIsDeleted
    },

    canDeleteUser(): boolean {
      return this.$auth.hasPrivilegeAnyClient("6600")
    },

    sortedClients(): InsightClientListModel[] {
      const clients = this.user.clients.filter(x => x.code > '');

      clients.sort((a,b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name == b.name) {
          return 0;
        } else {
          return -1;
        }
      })

      return clients;
    },

    groupsForSelectedClient(): GroupListModel[] {
      const groups = this.user.groups.filter(g => g.client == this.selectedClient.code)

      groups.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name == b.name) {
          return 0
        } else {
          return -1
        }
      })

      return groups
    },

    availableClients(): InsightClientListModel[] {
      const allClients = (this.$store.state as RootState).insightClients.clients

      const filteredClients = allClients.filter(c => this.user.clients.findIndex(x => x.code == c.code) == -1)

      return filteredClients
    },

    userStatus(): string {
      if (this.user.deletedBy > '') {
        return 'Deleted'
      } else if (this.user.active) {
        return 'Active'
      } else {
        return 'Invited'
      }
    },

    userStatusColor(): string {
      if (this.userStatus == 'Active') {
        return 'info--text'
      } else if (this.userStatus == 'Invited') {
        return 'warning--text'
      } else if (this.userStatus == 'Deleted') {
        return 'error--text'
      } else {
        return ""
      }
    },

    userIsDeleted(): boolean {
      return this.userStatus == "Deleted"
    },
  },

  watch: {
    "user.clients.length": {
      handler() {
        if (this.selectedClient.code > "") {
          const idx = this.user.clients.findIndex(x => x.code = this.selectedClient.code)

          if (idx < 0) {
            this.selectedClient = this.user.clients[0] ?? new InsightClientListModel();
          }
        } else if (this.user.clients.length > 0) {
          this.selectedClient = this.user.clients[0]
        }
      },
      immediate: true,
    }
  },

  mounted() {
    this.checkIfSelf()
  },

  methods: {
    async linkClient(client: InsightClientListModel): Promise<void> {
      this.showOverlay = true

      try {
        await this.$store.dispatch(ac.USER_LINK, {id: this.user.id, client: client.code})
      }
      catch (e) {
        notifications.fail(this.$store, "Failed to link user to client!")
      }

      this.showOverlay = false
    },

    async assignToGroup(group: GroupListModel): Promise<void> {
      this.showOverlay = true

      try {
        const spec = {id: this.user.id, client: this.selectedClient.code, slug: group.slug}
        await this.$store.dispatch(ac.USER_ASSIGN_GROUP, spec)
      } catch (e) {
        notifications.fail(this.$store, "Failed to assign user to group!")
      }

      this.showOverlay = false
    },

    handleClientClick(client: InsightClientListModel): void {
      this.selectedClient = client;
    },

    async deleteUser(confirmed: boolean): Promise<void> {
      if (confirmed) {
        this.showOverlay = true
    
        try {
          await this.$store.dispatch(ac.USER_DELETE, this.user)
        } catch (e) {
          notifications.fail(this.$store, "Failed to delete user")
        }

        this.showOverlay = false
      }
    },

    async undeleteUser(): Promise<void> {
      this.showOverlay = true
      
      try {
        await this.$store.dispatch(ac.USER_UNDELETE, this.user)
      } catch (e) {
        notifications.fail(this.$store, "Failed to undelete user")
      }

      this.showOverlay = false
    },

    async unlinkUser (client: InsightClientListModel) {
      this.unlinkingClientCode = client.code

      if (this.selectedClient.code == client.code) {
        const otherClients = this.user.clients.filter(x => x.code != client.code);
        this.selectedClient = otherClients[0] ?? new InsightClientListModel();
      }

      try {
        await this.$store.dispatch(ac.USER_UNLINK, {id: this.user.id, client: client.code})
      }
      catch (e) {
        notifications.fail(this.$store, "Failed to remove link to client.")
      }

      this.unlinkingClientCode = ""
    },

    checkIfSelf(): void {
      this.isSelf = ((this.$store.state as RootState).core.currentUserId == this.user.id)
    },
  },
})
