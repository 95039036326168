
import * as ac from "../../store/actionNames"
import * as rt from "../../router/routeNames"
import Vue from "vue"

import notifications from "../../util/notifications"

import NameField from "../common/NameField.vue"
import DescriptionField from "../common/DescriptionField.vue"
import PrivilegeChooser from '../privileges/PrivilegeChooser.vue'
import { Privilege } from "insight-auth/src/models/Privilege"

export default Vue.extend({
  components: {
    NameField,
    DescriptionField,
    PrivilegeChooser,
  },

  props: {
    value: Boolean,

    client: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      formValid: false,
      saveInProgress: false,
      name: "",
      description: "",
      isProtected: false,
      privileges: [] as Privilege[],
    }
  },

  computed: {
    canCreateProtectedGroups(): boolean {
      return this.$auth.hasPrivilegeAnyClient("5100")
    },
  },

  methods: {
    save(): void {
      this.saveInProgress = true

      const newGroup = {
        name: this.name,
        client: this.client,
        description: this.description,
        isProtected: this.isProtected,
        privileges: this.privileges,
      }

      this.$store.dispatch(ac.GROUP_SAVE_GROUP, newGroup)
      .then((data) => {
        this.saveInProgress = false
        this.resetAndClose()

        this.$router.push({name: rt.GROUPS, params: {clientCode: this.client, slug: data.slug}})
      })
      .catch((e) => {
        this.saveInProgress = false

        notifications.warn(this.$store, `Failed to create group. Message: ${e.data || e.errorCause || "(No message)"}`);
      })
    },

    resetAndClose(): void {
      this.name = ""
      this.description = "" 
      this.privileges = []
      this.isProtected = false

      this.getForm().resetValidation()

      this.$emit('input', false)
    },

    getForm(): {resetValidation: () => void} {
      return this.$refs.newGroupForm as unknown as {resetValidation: () => void}
    }
  },
})
