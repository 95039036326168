export enum AppCenterFileType {
  Excel = "xlsx",
  Jpeg = "jpg",
  Pdf = "pdf",
  Png = "png",
  Text = "txt",
  Word = "docx",
}

export function GetAppCenterFileColor(type: AppCenterFileType): string {
  if (type == AppCenterFileType.Pdf) {
    return 'red';

  } else if (type == AppCenterFileType.Excel) {
    return 'green';

  } else if (type == AppCenterFileType.Text) {
    return 'blue-grey';

  } else if (type == AppCenterFileType.Jpeg) {
    return 'purple';

  } else if (type == AppCenterFileType.Png) {
    return 'amber';

  } else if (type == AppCenterFileType.Word) {
    return 'blue';

  } else {
    return 'brown';
  }
}

export function GetAppCenterFileIcon(type: AppCenterFileType): string {
  if (type == AppCenterFileType.Pdf) {
    return 'mdi-file-pdf-outline';

  } else if (type == AppCenterFileType.Excel) {
    return 'mdi-file-table-outline';

  } else if (type == AppCenterFileType.Text) {
    return 'mdi-file-document-outline';

  } else if (type == AppCenterFileType.Jpeg || type == AppCenterFileType.Png) {
    return 'mdi-file-image-outline';

  } else if (type == AppCenterFileType.Word) {
    return 'mdi-file-word-outline';

  } else {
    return 'mdi-file-outline';
  }
}

export function GetAppCenterFileType(mimeType: string): string {
  if (mimeType == 'application/pdf') {
    return AppCenterFileType.Pdf
  } else if (mimeType == 'text/plain') {
    return AppCenterFileType.Text
  } else if (mimeType == 'image/jpeg') {
    return AppCenterFileType.Jpeg
  } else if (mimeType == 'image/png') {
    return AppCenterFileType.Png
  } else if (mimeType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return AppCenterFileType.Word
  } else if (mimeType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return AppCenterFileType.Excel
  } else {
    return '';
  }
}

export function GetAppCenterFileTypeDescription(type: AppCenterFileType): string {
  if (type == AppCenterFileType.Pdf) {
    return 'PDF';

  } else if (type == AppCenterFileType.Excel) {
    return 'Spreadsheet';

  } else if (type == AppCenterFileType.Text) {
    return 'Text File';

  } else if (type == AppCenterFileType.Jpeg || type == AppCenterFileType.Png) {
    return 'Image';

  } else if (type == AppCenterFileType.Word) {
    return 'Document';

  } else {
    return 'Type Unknown';
  }
}