import * as Ably from 'ably/promises'
import { Types } from 'ably/ably'
import { SecurityCacheControlEventSource } from 'insight-auth';
import { SecurityCacheControlEventHandler } from 'insight-auth/src/models/SecurityCacheControlEvents';

let client: Ably.Realtime;
let channel: Types.RealtimeChannelPromise;

function _processMessage(m: Types.Message): void {
  if (m.name == 'SecurityCacheControl') {
    const data = m.data as string[];
    
    if (cacheControlEventHandler) {
      cacheControlEventHandler({
        invalidIds: data,
      })
    }
  }
}

let cacheControlEventHandler: SecurityCacheControlEventHandler;

const securityCacheEventSource: SecurityCacheControlEventSource = {
  listen: function (handler: SecurityCacheControlEventHandler): void {
    cacheControlEventHandler = handler;
  }
}

export const RealtimeService = {
  init(key: string, clientCode?: string): void {
    const options: Ably.Types.ClientOptions = { key, echoMessages: false };
    client = new Ably.Realtime(options);

    channel = client.channels.get(`_scc`);
    channel.subscribe(_processMessage);

    if (clientCode) {
      this.setClient(clientCode);
    }
  },

  setClient(clientCode: string): void {
    channel = client.channels.get(`${clientCode}_cw`);
    channel.subscribe(_processMessage);
  },

  getConnectionIdentifier(): string|undefined {
    return client?.connection?.key;
  },

  getSecurityCacheControlEventSource(): SecurityCacheControlEventSource {
    return securityCacheEventSource;
  }
}