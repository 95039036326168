import * as rt from "./routeNames"
import Error from '../components/core/Error.vue'
import Dashboard from '../components/core/Dashboard.vue'
import GroupManager from "../components/groups/GroupManager.vue"
import UserManager from "../components/users/UserManager.vue"
import AcceptInvitation from "../components/users/AcceptInvitation.vue"
import SubscriptionManager from "../components/subscriptions/SubscriptionManager.vue"
import { InsightAuth } from "insight-auth"
import store from "@/store/store"

const clientCodeExpr = ":clientCode([a-zA-Z0-9]{3})"

const dashboardKey = "dashboard"
const groupKey = "groups:clientCode"
const userKey = "users:clientCode"
const acceptInviteKey = "accept:clientCode"
const subscriptionKey = "subscriptions:clientCode"

export default [
  {
    name: rt.DASHBOARD,
    path: '/' + clientCodeExpr + "?",
    component: Dashboard,
    meta: {
      animationKey: dashboardKey,
      authCheck(auth: InsightAuth, params: Record<string, string>) {
        if (params.clientCode) {
          return auth.hasClientAccess(params.clientCode)
        } else {
          return true
        }
      },
    },
  },
  {
    name: rt.GROUPS,
    path: "/" + clientCodeExpr + "/groups/:slug?",
    component: GroupManager,
    props: true,
    meta: {
      animationKey: groupKey,
      authCheck (auth: InsightAuth, params: Record<string, string>) {
        if (params.slug) {
          return (auth.hasPrivilegeAnyClient("4200") ||
            auth.hasPrivilegeForClient(params.clientCode, "4210")) &&
            (auth.hasPrivilegeAnyClient("5500") ||
            auth.hasPrivilegeForClient(params.clientCode, "5510"))
        } else {
          return auth.hasPrivilegeAnyClient("5500") ||
            auth.hasPrivilegeForClient(params.clientCode, "5510")
        }
      },
    },
  },
  {
    name: rt.USERS,
    path: '/' + clientCodeExpr + '/users/:id([0-9]+)?',
    component: UserManager,
    props: true,
    meta: {
      animationKey: userKey,
      authCheck (auth: InsightAuth, params: Record<string, string>) {
        if (params.id) {
          return auth.hasPrivilegeAnyClient("6200") ||
            auth.hasPrivilegeForClient(params.clientCode, "6210") ||
            (store.state.core.currentUserId == params.id && auth.hasPrivilegeForClient(params.clientCode, "6220"))
        } else {
          return auth.hasPrivilegeAnyClient("6100") ||
            auth.hasPrivilegeForClient(params.clientCode, "6110")
        }
      },
    }
  },

  {
    name: rt.ACCEPT_INVITATION,
    path: '/accept/:newClient([a-zA-Z0-9]{3})/:invitationCode([0-9A-Z]{4})',
    component: AcceptInvitation,
    props: true,
    meta: {
      animationKey: acceptInviteKey,
      hideNav: true,
    }
  },

  {
    name: rt.SUBSCRIPTIONS,
    path: '/' + clientCodeExpr + '/subscriptions',
    component: SubscriptionManager,
    props: true,
    meta: {
      animationKey: subscriptionKey,
      authCheck (auth: InsightAuth, params: Record<string, string>) {
        return auth.hasPrivilegeAnyClient("8200") ||
          auth.hasPrivilegeForClient(params.clientCode, "8210")
      },
    }
  },
  
  {
    name: rt.FORBIDDEN,
    path: '/unauthorized',
    component: Error,
    props: {httpErrorCode: 403}
  },

  {
    path: '*',
    component: Error,
    props: {httpErrorCode: 404}
  }
]
