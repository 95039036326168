import '@mdi/font/css/materialdesignicons.css'
import 'vue-file-agent/dist/vue-file-agent.css';
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import './plugins/auth'
import './plugins/router'
import store from './store/store'
import router from './router/router'
import App from './App.vue'
import VueFileAgent from 'vue-file-agent';

Vue.use(VueFileAgent);
Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')