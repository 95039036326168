
import * as mt from "../../store/mutationNames"
import InsightClientManager from '../insightClients/InsightClientManager.vue'
import Vue from 'vue';

export default Vue.extend({
    components: {
        InsightClientManager
    },

    created() {
        this.$store.commit(mt.CORE_SET_PAGE_TITLE, "Dashboard")
    }
});
