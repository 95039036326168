import api from '../../util/api'
import * as mt from "../mutationNames"
import * as ac from "../actionNames"
import * as gt from "../getterNames"
import { InsightClient } from '../../models/insightClients/InsightClient'
import { InsightClientListModel } from '@/models/insightClients/InsightClientListModel'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '../RootState'
import notifications from '@/util/notifications'
import store from '../store'

export class ClientState {
  clients: InsightClientListModel[] = [];
  selectedClientIndex = 0
}

const getters: GetterTree<ClientState, RootState> = {
  [gt.CLIENT_CURRENT] (state) {
    if (state.selectedClientIndex < state.clients.length && state.selectedClientIndex >= 0) {
      return state.clients[state.selectedClientIndex]
    } else {
      return null;
    }
  },

  [gt.CLIENT_BY_CODE]: (state) => (code: string) => {
    return state.clients.find(client => client.code == code)
  },

  [gt.CLIENT_INDEX_BY_CODE]: (state) => (code: string) => {
    return state.clients.findIndex(client => client.code == code)
  }
}

const mutations: MutationTree<ClientState> = {
  [mt.CLIENT_REPLACE_ALL] (state, clients: InsightClientListModel[]) {
    state.clients = clients;
  },

  [mt.CLIENT_SET_CURRENT] (state, newIndex: number) {
    if (state.clients.length > newIndex && newIndex >= 0) {
      state.selectedClientIndex = newIndex;
    } else {
      throw new Error("Invalid client index!");
    }
  },

  [mt.CLIENT_ADD_OR_UPDATE] (state, client: InsightClient) {
    const idx = state.clients.findIndex(c => c.code == client.code)

    const clientListModel = new InsightClientListModel();
    clientListModel.code = client.code
    clientListModel.name = client.name;

    if (client.logo) {
      clientListModel.logo = `/api/v1/${client.code}/files/public/data/${client.logo.id}`;
    }

    if (idx >= 0) {
      state.clients.splice(idx, 1, clientListModel)
    } else {
      state.clients.push(clientListModel)
    }
  },

  [mt.CLIENT_REMOVE] (state, client: InsightClientListModel) {
    const idx = state.clients.findIndex(c => c.code == client.code)

    if (idx >= 0) {
      if (state.selectedClientIndex != idx) {
        state.clients.splice(idx, 1)

        if (state.selectedClientIndex > idx) state.selectedClientIndex--;
      }
    } else {
      throw new Error("Attempted to remove client that didn't exist! Code: " + client.code)
    }
  },
}

const actions: ActionTree<ClientState, RootState> = {

  async [ac.CLIENT_FETCH_ALL] ({commit, getters}) {

    //First get the client list from the server
    const clientResponse = await api.get("insightClients")

    if (!clientResponse) return;

    if (clientResponse.error) {
      return Promise.reject(clientResponse);
    }

    const clients = clientResponse.data

    //Now get the selected client
    const prevClient = getters[gt.CLIENT_CURRENT] || {}

    commit(mt.CLIENT_REPLACE_ALL, clients)

    const newIndex = getters[gt.CLIENT_INDEX_BY_CODE](prevClient.code)
    commit(mt.CLIENT_SET_CURRENT, (newIndex > 0) ? newIndex : 0)
  },

  async [ac.CLIENT_SAVE] ({commit, getters}, data: InsightClient) {
    const clientIdx = getters[gt.CLIENT_INDEX_BY_CODE](data.code)

    let response;

    if (clientIdx >= 0) {
      response = await api.update("insightclients/" + data.code, data)
    } else {
      response = await api.create("insightClients", data);
    }

    if (!response) return;

    if (response.error) {
      return Promise.reject(response)
    }

    commit(mt.CLIENT_ADD_OR_UPDATE, response.data);
  },

  async [ac.CLIENT_DELETE] ({commit, getters, state}, data: InsightClient) {
    const clientIdx = getters[gt.CLIENT_INDEX_BY_CODE](data.code)

    let response;

    if (clientIdx >= 0) {
      if (clientIdx == state.selectedClientIndex) {
        notifications.warn(store, "You cannot delete the current client. please change clients before deleting this one.")
        return
      }

      response = await api.delete("insightclients/" + data.code, [])
    } else {
      response = null;
    }

    if (!response) return;

    if (response.error) {
      return Promise.reject(response)
    }

    commit(mt.CLIENT_REMOVE, data);
  },
}

export default {
  actions,
  mutations,
  state: new ClientState(),
  getters
}