
import * as gt from "../../store/getterNames"
import * as mt from "../../store/mutationNames"
import Vue from "vue"
import { InsightNotification } from "@/models/core/InsightNotification"

export default Vue.extend({
  data() {
    return {
      showSnackbar: false,
    }
  },

  computed: {
    notificationCount(): number {
      return this.$store.getters[gt.CORE_NOTIFICATION_COUNT]
    },

    nextNotification(): InsightNotification {
      return this.$store.getters[gt.CORE_NEXT_NOTIFICATION]
    },
  },

  watch: {
    notificationCount () {
      if (this.notificationCount > 0 && this.showSnackbar == false) {
        this.$nextTick(() => this.showSnackbar = true)
      }
    }
  },

  methods: {
    dismissSnackbar () {
      this.showSnackbar = false

      this.$store.commit(mt.CORE_REMOVE_NOTIFICATION, 0)
    }
  },
})
