
import Vue from 'vue';

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: '',  
    },

    value: {
      type: String,
      default: '',
    },

    required: Boolean,
  },
  data () {
    return {
      rules: [
        (v?: string) => !this.required || !!v || (this.label + " is required"),
        (v?: string) => (v || "").length <= 150 || (this.label + " is too long")
      ]
    }
  },

  methods: {
    handleInput(payload: never) {
      this.$emit("input", payload)
    }
  },
})
