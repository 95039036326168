
import Vue from 'vue'
import AppSidebar from './components/core/AppSidebar.vue'
import Notifier from "./components/core/Notifier.vue"
import * as gt from "./store/getterNames"
import { RootState } from './store/RootState'

export default Vue.extend({

  components: {
    AppSidebar,
    Notifier,
  },
  data () {
    return {
    }
  },

  computed: {
    routeKey(): string {
      //If the route has an animation key, use that. Otherwise, use the full path.
      return this.animationKey || this.$route.fullPath
    },

    animationKey(): string|false {
      const template = this.$route.meta?.animationKey

      if (!template) return false

      const parts = template.split(":")

      for (var i = 1; i < parts.length; i++) {
        parts[i] = this.$route.params[parts[i]] || "undefined"
      }

      return parts.join("_")
    },

    isLoggedIn(): boolean {
      return (this.$store.state as RootState).core.currentUserId > ''
    },

    isLoaded(): 'yes'|'no'|'failed' {
      return this.$store.state.core.appDataLoaded
    },

    pageTitle(): string {
      const store = this.$store
      var titleParts = []

      titleParts.push(store.state.core.pageTitle)
      titleParts.push((store.getters[gt.CLIENT_CURRENT] || {}).name)
      titleParts.push("Insight App Center")

      titleParts = titleParts.filter(p => {return p > ""})

      return titleParts.join(" - ")
    },

    showNav(): boolean {
      return this.isLoggedIn && this.isLoaded == 'yes' && !(this.$route.meta || {}).hideNav
    }
  },

  watch: {
    pageTitle () {
      document.title = this.pageTitle
    }
  },
})
