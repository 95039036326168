
import { AppCenterFile } from "@/models/files/AppCenterFile"
import { InsightClient } from "@/models/insightClients/InsightClient"
import environment from "@/util/environment"
import Vue from "vue"
import { PropValidator } from "vue/types/options"
import * as ac from "../../store/actionNames"
import BufferedEditor from '../common/BufferedEditor.vue'
import DoubleConfirmDlg from '../common/DoubleConfirmDlg.vue'
import NameField from "../common/NameField.vue"
import FileUploader from "../files/FileUploader.vue"
import PhoneNumberField from "../common/PhoneNumberField.vue"
import EmailField from '../common/EmailField.vue'
import notifications from "@/util/notifications"

export default Vue.extend({
  components: {
    BufferedEditor,
    NameField,
    DoubleConfirmDlg,
    FileUploader,
    PhoneNumberField,
    EmailField,
  },

  props: {
    client: {
      type: Object,
      default: null,
    } as PropValidator<InsightClient>,

    show: Boolean,
  },
  
  data () {
    return {
      deleteInProgress: false,
      saveEditor: false,
      cancelEditor: false,
      formValid: false,
      saveInProgress: false,
      saveActionName: ac.CLIENT_SAVE,
      showDeleteConfirm: false,
      showUploadLogo: false,
      stateList: [
        'AB', 'BC', 'MB', 'NB', 'NF', 'NS', 'NT', 'ON', 'PE',
        'QC', 'SK', 'YT', 'AK', 'AL', 'AR', 'AS', 'AZ', 'CA',
        'CO', 'CT', 'DC', 'DE', 'FL', 'FM', 'GA', 'GU', 'HI',
        'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD',
        'ME', 'MH', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC',
        'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK',
        'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'
      ]
    }
  },

  computed: {
    canDeleteClient(): boolean {
      return this.$auth.hasPrivilegeAnyClient("2400")
    },

    environment(): string {
      return environment;
    },

    logoUrl(): string {
      if (!this.client) return "";

      if (!this.client.logo) return "";

      if (environment == 'dev') {
        return "http://localhost:5000" + this.client.logo.id;
      } else {
        return this.client.logo.id;
      }
    },
  },

  methods: {
    handleBufferSave(data: string): void {
      this.saveInProgress = (data == "started") ? true : false;

      if (data == "succeeded") {
        this.$emit('update:show', false);
      }
    },

    handleBufferCancel(isCanceled: boolean): void {
      if (isCanceled) {
        this.$emit('update:show', false);
      }
    },

    async handleDelete(confirmed: boolean): Promise<void> {
      if (confirmed) {
        this.deleteInProgress = true;
        try {
          await this.$store.dispatch(ac.CLIENT_DELETE, this.client)
          this.$emit('update:show', false);
        } catch (error) {
          notifications.fail(this.$store, "Failed to delete the client!");
        }

        this.deleteInProgress = false;
      }
    },

    handleLogoUpload(buffer: InsightClient, logo: AppCenterFile): void {
      Vue.set(buffer, "logo", logo);
      this.showUploadLogo = false;
    }
  },
})
