//Core
export const CORE_SET_APP_DATA_LOAD_STATE = "coreAppDataLoadState"
export const CORE_TOGGLE_SIDENAV_MINI = "coreToggleSidenavMini"
export const CORE_ADD_NOTIFICATION = "coreAddNotification"
export const CORE_REMOVE_NOTIFICATION = "coreRemoveNotification"
export const CORE_SET_PAGE_TITLE = "coreSetPageTitle"
export const CORE_SET_CURRENT_USER_ID = "coreSetCurrentUserId"
export const CORE_SET_APP_CONFIG = "coreSetAppConfig";

//Insight Clients module
export const CLIENT_REPLACE_ALL = "clientReplaceAll"
export const CLIENT_SET_CURRENT = "clientSetCurrent"
export const CLIENT_ADD_OR_UPDATE = "clientAddOrUpdate"
export const CLIENT_REMOVE = "clientRemove"

//Groups module
export const GROUP_REPLACE_ALL_FOR_CLIENT = "groupReplaceAll"
export const GROUP_ADD_OPEN_GROUP = "groupAddOpenGroup"
export const GROUP_REMOVE_OPEN_GROUP = "groupRemoveOpenGroup"
export const GROUP_UPDATE_OPEN_GROUP = "groupUpdateOpenGroup"
export const GROUP_PURGE = "groupPurge"

//Privileges module
export const PRIVILEGE_REPLACE_LIST = "privilegeReplaceList"
export const PRIVILEGE_REPLACE_ACTIVE = "privilegeReplaceActive"

//Users module
export const USER_LIST_SET_FOR_CLIENT = "userListSetForClient"
export const USER_ADD_OR_REPLACE_OPEN_USER = "userAddOpenUser"
export const USER_REMOVE_OPEN_USER = "userRemoveOpenUser"
export const USER_REMOVE_FROM_CLIENT = "userRemoveFromClient"
export const USER_PURGE = "userPurge"
export const USER_SET_MANAGE_DELETED_USERS = "userSetManageDeletedUsers"
export const USER_SET_MANAGE_ALL_USERS = "userSetManageAllUsers"

//Subscriptions
export const SUBSCRIPTION_REPLACE_ALL_FOR_CLIENT = "subscriptionReplaceAll"
export const SUBSCRIPTION_ADD_OR_UPDATE = "subscriptionAdd"
export const SUBSCRIPTION_PURGE = "subscriptionPurge"
export const SUBSCRIPTION_SET_MANAGE_DELETED_SUBSCRIPTIONS = "subscriptionSetManageDeletedSubscriptions"

//Features
export const FEATURE_REPLACE_APP_LIST = "appReplaceList"
export const FEATURE_REPLACE_FEATURES_FOR_APP = "featureReplaceList"