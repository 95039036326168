
import { Privilege } from "@/models/privileges/Privilege"
import { PrivilegeListModel } from "@/models/privileges/PrivilegeListModel"
import { RootState } from "@/store/RootState"
import Vue from "vue"
import { PropValidator } from "vue/types/options"

export default Vue.extend({
  props: {
    client: {
      type: String,
      default: '',
      required: true,
    },

    privileges: {
      type: Array,
      default: null,
    } as PropValidator<PrivilegeListModel[]>,

    readonly: Boolean,
  },

  data () {
    return {
      nodeIconsById: {} as Record<string, string>,
      sortedPrivileges: [] as Privilege[],
    }
  },

  computed: {
    allPrivileges(): Privilege[] {
      const store = (this.$store.state as RootState);

      return store.privileges.privileges;
    },

    canAssignProtected(): boolean {
      return this.$auth.hasPrivilegeAnyClient("4000");
    },

    privilegeTree(): Privilege[] {
      const store = (this.$store.state as RootState);

      return store.privileges.privilegeTree;
    },

    universalPrivileges(): Privilege[] {
      return this.allPrivileges.filter(x => x.isUniversal);
    }
  },

  watch: {
    privileges: {
      handler () {
        if (this.privileges != null) {
          this.updatePrivileges()
        }
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    configureNodeIcons(): void {
      this.nodeIconsById = {};

      this.sortedPrivileges.forEach(p => {
        const privilege = this.allPrivileges.find(x => x.id == p.id);
        if (privilege)
          this.configureNodeIconsForPrivilege(privilege);
      });
    },

    configureNodeIconsForPrivilege(p: Privilege, fromChild = false): void {
      const marked = 'mdi-checkbox-marked';
      const childMarked = 'mdi-checkbox-intermediate';
      let icon = '';

      if (this.nodeIconsById[p.id] == marked) {
        icon = marked;
      } else {
        icon = fromChild ? childMarked : marked;
      }

      Vue.set(this.nodeIconsById, p.id, icon);

      if (p.parent > "") {
        const myParent = this.allPrivileges.find(x => x.id == p.parent);

        if (myParent) {
          this.configureNodeIconsForPrivilege(myParent, true);
        }
      }
    },

    getColorForPrivilege(p: Privilege): string {
      const thousandsChar = p.id.substring(p.id.length-4, p.id.length-3);
      const idx = +(thousandsChar);

      const colors = ['green','red','indigo','teal','brown','blue','blue-grey','purple','orange','cyan'];

      return colors[idx] + " lighten-4";
    },

    handleIconClick(p: Privilege) {
      if (p.isProtected && !this.canAssignProtected) return;
      if (p.isUniversal) return;

      const idx = this.sortedPrivileges.findIndex(x => x.id == p.id);

      if (idx > -1) {
        this.sortedPrivileges.splice(idx, 1);
      } else {
        this.sortedPrivileges.push(p);
      }

      this.handleSelectionEvent();
    },

    handleSelectionEvent () {
      this.sortSelectedPrivileges()

      const data = JSON.parse(JSON.stringify(this.sortedPrivileges)) as Privilege[]

      data.sort((a,b) => +(a.id) - +(b.id))

      this.$emit("update:privileges", data)
    },

    removeChip(privilege: Privilege): void {
      const idx = this.sortedPrivileges.findIndex(i => i.id == privilege.id)

      if (idx > -1) {
        this.sortedPrivileges.splice(idx, 1)
      }

      this.handleSelectionEvent();
    },

    sortSelectedPrivileges () {
      this.sortedPrivileges.sort((a,b) => {
        if (a.name > b.name) {
            return 1
        } else if (a.name < b.name) {
            return -1
        } else {
            return 0
        }
      })
    },

    updatePrivileges(): void {
      this.sortedPrivileges = JSON.parse(JSON.stringify(this.privileges))
      this.sortSelectedPrivileges()
      
      this.configureNodeIcons();
    },
  },
})
