
import Vue from "vue"

const ALLOWED_CHARS = /[A-Z0-9]*/

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },
    
    required: Boolean,
  },

  data() {
    return {
      allowedChars: ALLOWED_CHARS,
      rules: [
        (v?: string) => !this.required || !!v || (this.label + " is required"),
        (v?: string) => (v || "").length == 3 || (this.label + " must have 3 characters"),
        (v?: string) => ALLOWED_CHARS.test(v || "") || (this.label + " must have only numbers and upper-case letters")
      ]
    }
  },
  methods: {
    handleInput(payload: never): void {
      this.$emit("input", payload)
    }
  },
})
