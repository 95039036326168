
import environment from '@/util/environment'
import { SubscriptionProvisioningMessage } from '../../models/subscriptions/SubscriptionProvisioningMessage'
import Vue from 'vue'
import { InsightApplication } from 'insight-auth';
import { SubscriptionUpdateEvent } from '@/models/subscriptions/SubscriptionUpdateEvent';

const allowedOrigin = environment == 'dev' ? 'http://localhost:8081' : 'https://werk365.com';
const allowedSource = "PROVISIONING_SEQUENCE";
const mySource = "APP_CENTER_PROVISIONING_SERVICE"

let messageHandlerImpl: (e: MessageEvent<SubscriptionProvisioningMessage>) => void;

export default Vue.extend({
  props: {
    clientCode: {
      type: String,
      default: '',
    },

    show: Boolean,

    stepNumber: {
      type: Number,
      default: 1,
    },

    subscription: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      height: '700px',
      loading: true,
      targetUrl: '',
      width: '1000px',
    }
  },

  watch: {
    show() {
      if (this.show) {
        this.loading = true;
        this.$auth.getBearerAuthForApi(InsightApplication.Werk)
          .then(() => this.targetUrl = this.calculateUrl(this.clientCode, this.subscription))
      }
    }
  },

  mounted(): void {
    messageHandlerImpl = (e) => this.messageHandler(e)
    window.addEventListener('message', messageHandlerImpl);
  },

  beforeDestroy(): void {
    if (messageHandlerImpl) window.removeEventListener('message', messageHandlerImpl);
  },

  methods: {
    calculateUrl(clientCode: string, subscription: string): string {
      return `${allowedOrigin}/provisioning/${clientCode}/${subscription}`
    },

    cancelLoading(): void {
      this.$emit('update:show', false);
      this.targetUrl = '';
      this.$emit('update-subscription', new SubscriptionUpdateEvent(false, this.stepNumber))
    },

    messageHandler(evt: MessageEvent<SubscriptionProvisioningMessage>): void {
      if (evt.origin == allowedOrigin && evt.data.source == allowedSource) {
        const msg = evt.data;

        if (msg.requestedAction == 'endLoading') {
          this.loading = false;

          this.sendSetStepMessage();
        } else {
          this.$emit('update:show', false);
          this.targetUrl = '';
          this.$emit('update-subscription', new SubscriptionUpdateEvent(msg.complete, msg.currentStep))
        }
      }
    },

    sendSetStepMessage(): void {
      const werkWindow = (document.getElementById('provisioningIframe') as HTMLIFrameElement).contentWindow;

      werkWindow?.postMessage(
        new SubscriptionProvisioningMessage(mySource, "setStep", false, this.stepNumber),
        allowedOrigin
      )
    }
  }
})
