
import Vue from "vue"
import * as gt from "../../store/getterNames"
import * as mt from "../../store/mutationNames"
import * as rt from "../../router/routeNames"
import environment from "../../util/environment"
import { PropValidator } from "vue/types/options"
import { InsightClientListModel } from "@/models/insightClients/InsightClientListModel"

export default Vue.extend({
  props: {
    client: {
      type: Object,
      default: null,
    } as PropValidator<InsightClientListModel>,

    loading: Boolean,
  },

  computed: {
    subscribedToWerk(): boolean {
      return this.$auth.hasFeatureForClient(this.client.code, '1000000');
    },

    werkUrl(): string {
      const stem = environment == 'production' ? 'https://werk365.com/' : 'http://localhost:8081/'
      return stem + this.client.code
    },

    subscribedToWispa(): boolean {
      return this.$auth.hasFeatureForClient(this.client.code, '2000000');
    },

    isSelected(): boolean {
      const currentClient = (this.$store.getters[gt.CLIENT_CURRENT] || {}) as InsightClientListModel

      return currentClient.code == this.client.code
    },

    titleColor(): string {
      return this.isSelected ? "white--text" : ""
    },

    titleBarColor(): string {
      return this.isSelected ? "primary" : ""
    },

    tileColor(): string|undefined {
      if (this.isSelected) {
        return undefined // "accent lighten-4"
      } else {
        return undefined
      }
    },

    canEditClients(): boolean {
      return this.$auth.hasPrivilegeAnyClient("2200") ||
              this.$auth.hasPrivilegeForClient(this.client.code, "2210")
    },

    logoUrl(): string {
      if (!this.client) return "";

      if (!this.client.logo) return "";

      if (environment == 'dev') {
        return "http://localhost:5000" + this.client.logo;
      } else {
        return this.client.logo;
      }
    },
  },

  methods: {
    handleClientClicked () {
      const currentClient = this.$store.getters[gt.CLIENT_CURRENT] as InsightClientListModel;

      if (currentClient.code == this.client.code) return;

      const idx = this.$store.getters[gt.CLIENT_INDEX_BY_CODE](this.client.code)

      this.$store.commit(mt.CLIENT_SET_CURRENT, idx)

      this.$router.replace({name: rt.DASHBOARD, params: {clientCode: this.client.code}})
    },

    handleEditRequested(clientCode: string): void {
      this.$emit('edit-requested', clientCode);
    }
  }
})
