
import Vue from "vue"
import * as ac from "../../store/actionNames"
import notifications from "../../util/notifications"
import ConfirmDlg from '../common/ConfirmDlg.vue'
import { PropValidator } from 'vue/types/options'
import { GroupListModel } from '@/models/groups/GroupListModel'
import { InsightUser } from "@/models/users/InsightUser"

export default Vue.extend({
  components: {
    ConfirmDlg,
  },

  props: {
    group: {
      type: Object,
      default: null,
    } as PropValidator<GroupListModel>,

    user: {
      type: Object,
      default: null,
    } as PropValidator<InsightUser>,
  },

  data () {
    return {
      showConfirmDlg: false,
      working : false,
    }
  },

  computed: {
    canRemoveFromGroup(): boolean {
      const auth = this.$auth

      return (auth.hasPrivilegeAnyClient("5200") ||
        (auth.hasPrivilegeAnyClient("5210") && !this.group.isProtected)||
        (auth.hasPrivilegeForClient(this.group.client, "5220") && !this.group.isProtected)) &&
        !this.userIsDeleted
    },

    userIsDeleted(): boolean {
      return this.user.deletedBy > ""
    },
  },

  methods: {
    async removeGroupAssignment(doRemove: boolean): Promise<void> {
      if (!doRemove) return;

      this.working = true
      
      try {
        const spec = {id: this.user.id, client: this.group.client, slug: this.group.slug}
        await this.$store.dispatch(ac.USER_REMOVE_GROUP, spec)
      } catch (e) {
        notifications.fail(this.$store, "Failed to remove user from group!")
      }

      this.working = false
    }
  }
})
