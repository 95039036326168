import { GroupListModel } from "../groups/GroupListModel";
import { InsightClientListModel } from "../insightClients/InsightClientListModel";
import { InsightUser } from "./InsightUser";

export class InsightUserInvitation extends InsightUser {
  constructor(
    name: string,
    public invitationEmail: string,
    clients: InsightClientListModel[],
    groups: GroupListModel[]
  ) {
    super('', name, '', true, clients, groups)
  }
}