
import { Group } from "@/models/groups/Group"
import Vue from "vue"
import { PropValidator } from "vue/types/options"

export default Vue.extend({
  props: {
    group: {
      type: Object,
      default: null,
    } as PropValidator<Group>
  },

  computed: {
    protectionIcon(): string {
      return this.group.isProtected ? "mdi-shield-key" : "mdi-lock-open"
    }
  },
})
