import { ActionTree, GetterTree, MutationTree } from "vuex";
import api from "../../util/api"
import * as gt from "../getterNames"
import * as mt from "../mutationNames"
import * as ac from "../actionNames"
import { RootState } from "../RootState";
import { Application } from "../../models/features/Application";
import { Feature } from "../../models/features/Feature";

export class FeatureState {
    applications: Application[] = [];
    features: Feature[] = [];
}

const getters: GetterTree<FeatureState, RootState> = {
  [gt.FEATURES_FOR_APPLICATION] (state) {
    return (application: string) => {
        return state.features.filter(f => f.application = application)
    }
  }
}

const mutations: MutationTree<FeatureState> = {
    [mt.FEATURE_REPLACE_APP_LIST] (state, apps) {
        state.applications = apps
    },

    [mt.FEATURE_REPLACE_FEATURES_FOR_APP] (state, featureData: {features: Feature[], application: string}) {
        const filteredList = state.features.filter(f => f.application != featureData.application)
        const safeNewFeatures = featureData.features.filter(f => f.application == featureData.application)

        filteredList.push(...safeNewFeatures)

        state.features = filteredList
    },
}

const actions: ActionTree<FeatureState, RootState> = {
    async [ac.FEATURE_FETCH_ALL_APPLICATIONS] ({commit}) {
        const url = "/applications"

        const response = await api.get(url)

        if (!response) return;

        if (response.error) {
            return Promise.reject(response);
        }

        const applications = response.data

        commit(mt.FEATURE_REPLACE_APP_LIST, applications)
    },

    async [ac.FEATURE_FETCH_ALL_FEATURES] ({commit}, application) {
        const url = "/applications/" + application + "/features"

        const response = await api.get(url)

        if (!response) return;

        if (response.error) {
            return Promise.reject(response);
        }

        const features = response.data

        commit(mt.FEATURE_REPLACE_FEATURES_FOR_APP, {application, features})
    }
}

export default {
  actions,
  mutations,
  state: new FeatureState(),
  getters
}
