//Core
export const CORE_NOTIFICATION_COUNT = "coreNotificationCount"
export const CORE_NEXT_NOTIFICATION = "coreNextNotification"

//InsightClients
export const CLIENT_CURRENT = "clientCurrent"
export const CLIENT_BY_CODE = "clientByCode"
export const CLIENT_INDEX_BY_CODE = "clientIndexByCode"

//Groups
export const GROUPS_FOR_CURRENT_CLIENT = "groupsForCurrentClient"
export const GROUPS_OPEN_FOR_CURRENT_CLIENT = "groupsOpenForCurrentClient"
export const GROUPS_FOR_CLIENT = "groupsForClient"

//Users
export const USERS_FOR_CURRENT_CLIENT = "usersForCurrentClient"
export const USERS_OPEN_FOR_CURRENT_CLIENT = "usersOpenForCurrentClient"

//Subscriptions
export const SUBSCRIPTIONS_FOR_CURRENT_CLIENT = "subscriptionsForCurrentClient"

//Features
export const FEATURES_FOR_APPLICATION = "featuresForApplication"