
import Vue from "vue"
import * as rt from "../../router/routeNames"
import * as errorCodes from "../../util/apiErrors"
import api from "../../util/api"
import { RawLocation } from 'vue-router'
import { InsightUser } from "@/models/users/InsightUser"
import { RootState } from "@/store/RootState"

export default Vue.extend({
  props: {
    newClient: {
      type: String,
      default: '',
    },

    invitationCode: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      acceptanceComplete: false,
      acceptanceError: false,
      accepting: false,
      checkingExistence: true,
      invitationExists: false,
      loggingOut: false,
      user: false as false|InsightUser
    }
  },

  computed: {
    alreadyJoined(): boolean {
      return this.$auth.hasClientAccess(this.newClient.toUpperCase())
    },

    alreadyClaimed(): boolean {
      return !this.invitationExists && !this.checkingExistence && !this.alreadyJoined
    },

    targetUrl(): RawLocation {
      return {name: rt.DASHBOARD, params: {clientCode: this.newClient.toUpperCase()}}
    },

    userName(): string {
      return this.user ? this.user.name : "Loading...";
    },

    invitiationCodeDisplay(): string {
      return this.invitationCode.toUpperCase()
    }
  },

  mounted() {
    this.checkInvitationCode()
  },

  methods: {
    async acceptInvitation () {
      this.accepting = true

      var result = await api.update("accept/" + this.invitationCode, null)

      if (!result) {
        this.acceptanceError = true;
        return;
      }

      if (result.error) {
        this.acceptanceError = true
      } else {
        this.acceptanceComplete = true
      }

      this.accepting = false
    },

    async checkInvitationCode(): Promise<void> {
      this.checkingExistence = true

      var result = await api.get("accept/" + this.invitationCode)

      if (!result) {
        this.invitationExists = false;
        return;
      }

      if (result.error) {
        if (result.errorCause == errorCodes.NOT_FOUND) {
          this.invitationExists = false

          if (this.alreadyJoined) {
              this.acceptanceComplete = true
          }
        } else {
          this.acceptanceError = true
        }
      } else {
        this.invitationExists = true
      }
      
      this.checkingExistence = false
    },

    done () {
      if (this.alreadyJoined) {
        this.$router.replace(this.targetUrl)
      } else {
        this.logOut()
      }
    },

    async fetchUser(): Promise<void> {
      const id = (this.$store.state as RootState).core.currentUserId;

      const result = await api.get(`users/${id}`);

      if (!result) return;

      if (!result.error) {
        this.user = result.data as InsightUser;
      }
    },

    logOut () {
      this.loggingOut = true
      this.$auth.logout()
    },
  }
})
