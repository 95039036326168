
import Vue from "vue"
import * as gt from "../../store/getterNames"
import * as mt from "../../store/mutationNames"
import * as ac from "../../store/actionNames"
import * as rt from "../../router/routeNames"
import notifications from "../../util/notifications"
import UserList from "./UserList.vue"
import UserProfile from "./UserProfile.vue"
import NewUserInvitation from './NewUserInvitation.vue'
import { InsightUserListModel } from "@/models/users/InsightUserListModel"
import { InsightUser } from "@/models/users/InsightUser"
import envName from "@/util/environment"
import api from "@/util/api"
import { RootState } from "@/store/RootState"

const USER_TITLE = "Users"
const NAV_EXTERNAL = "external"
const NAV_INTERNAL = "internal"
const NAV_NONE = ""

export default Vue.extend({
  components: {
    NewUserInvitation,
    UserList,
    UserProfile,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
    },

    id: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      showNewUserDlg: false,
      loadingUsers: false,
      selectedTab: 0,
      navigationSource: NAV_NONE,
      openingUser: false,
      showAllUsers: false,
      showDeletedUsers: false,
      importingFromProduction: false,
    }
  },

  computed: {
    users(): InsightUserListModel[] {
      if (this.showAllUsers) {
        return (this.$store.state as RootState).users.users;
      } else {
        return this.$store.getters[gt.USERS_FOR_CURRENT_CLIENT]
      }
    },

    clientName(): string {
      return this.$store.getters[gt.CLIENT_CURRENT].name
    },

    openUsers(): InsightUser[] {
      if (this.showAllUsers) {
        return (this.$store.state as RootState).users.openUsers;
      } else {
        return this.$store.getters[gt.USERS_OPEN_FOR_CURRENT_CLIENT]
      }
    },

    canViewUsers(): boolean {
      return this.$auth.hasPrivilegeAnyClient("6100") ||
        this.$auth.hasPrivilegeForClient(this.clientCode, "6110")
    },

    canInviteUsers(): boolean {
      return this.$auth.hasPrivilegeAnyClient("6300") ||
        this.$auth.hasPrivilegeForClient(this.clientCode, "6310")
    },

    canEditUsers(): boolean {
      return this.$auth.hasPrivilegeAnyClient("6200") ||
        this.$auth.hasPrivilegeForClient(this.clientCode, "6210")
    },

    canDeleteUsers(): boolean {
      return this.$auth.hasPrivilegeAnyClient("6600")
    },

    canImportUsers(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1000") && envName != 'production'
    },

    canShowAllUsers(): boolean {
      return this.$auth.hasPrivilegeAnyClient("6000");
    },

    listTitle(): string {
      if (this.showAllUsers) {
        return "All Users From All Clients";
      } else {
        return `Users for ${this.clientName}`;
      }
    },
  },

  watch: {
    selectedTab(): void {
      this.setTitle(this.selectedTab)

      if (this.navigationSource == NAV_NONE) {
        this.navigationSource = NAV_INTERNAL

        this.setUrl(this.selectedTab)
      } else if (this.navigationSource == NAV_EXTERNAL) {
        this.$nextTick(() => this.navigationSource = NAV_NONE)
      }
    },

    async $route(): Promise<void> {
        if (this.navigationSource == NAV_NONE) {
          this.navigationSource = NAV_EXTERNAL

          if (this.id) {
            await this.showUser(new InsightUserListModel(this.id))
          } else {
            this.selectedTab = 0
          }
        } else if (this.navigationSource == NAV_INTERNAL) {
          this.$nextTick(() => this.navigationSource = NAV_NONE)
        }
    },
  },

  async created() {
    this.$store.commit(mt.CORE_SET_PAGE_TITLE, USER_TITLE)

    await this.loadUsers()

    if (this.id) {
      this.navigationSource = NAV_EXTERNAL
      await this.showUser(new InsightUserListModel(this.id))
    }
  },

  methods: {
    async loadUsers(): Promise<void> {
      this.loadingUsers = true

      try {
        await this.$store.dispatch(ac.USER_FETCH_ALL)
      } catch (e) {
        notifications.fail(this.$store, "Failed to load users!")
      }

      this.loadingUsers = false
    },

    async openUser (user: InsightUserListModel): Promise<boolean> {
      if (!this.canEditUsers && user.id != (this.$store.state as RootState).core.currentUserId) {
        notifications.warn(this.$store, "You do not have permission to edit this user.")
        return false
      } else {
        this.openingUser = true

        var success = false

        try {
          await this.$store.dispatch(ac.USER_LOAD_USER, {client: this.clientCode, id: user.id})
          success = true
        } catch (e) {
          notifications.fail(this.$store, "Failed to load user!")
        }

        this.openingUser = false

        return success
      }
    },

    async showUser(user: InsightUserListModel, blockLoop = false): Promise<void> {
      const idx = this.openUsers.findIndex(u => {return u.id == user.id})

      if (idx >= 0) {
        this.selectedTab = idx + 1
      } else if (!blockLoop) {
        const success = await this.openUser(user)

        if (success) {
          this.showUser(user, true)
        }
      }
    },

    handleTabCloseRequest(user: InsightUserListModel|InsightUser) {
      this.$store.commit(mt.USER_REMOVE_OPEN_USER, user)
    },

    setUrl(idx: number): void {
      const adjustedIdx = idx -1

      const id = adjustedIdx < 0 ? '' : this.openUsers[adjustedIdx].id ?? ''

      const params: Record<string, string> = {clientCode: this.clientCode}

      if (id > '') {
        params.id = id;
      }

      this.$router.push({name: rt.USERS, params})
    },

    setTitle (idx: number): void {
      const adjustedIdx = idx -1

      const title = adjustedIdx < 0 ? USER_TITLE : this.openUsers[adjustedIdx].name

      this.$store.commit(mt.CORE_SET_PAGE_TITLE, title)
    },

    async updateShowDeletedUsers(): Promise<void> {
      this.$store.commit(mt.USER_SET_MANAGE_DELETED_USERS, this.showDeletedUsers)

      await this.loadUsers()
    },

    async updateShowAllUsers(): Promise<void> {
      this.$store.commit(mt.USER_SET_MANAGE_ALL_USERS, this.showAllUsers)

      await this.loadUsers()
    },

    async runImportFromProduction(): Promise<void> {
      const doImport = confirm("Are you sure you want to sync users from production?");

      if (!doImport) return;

      this.importingFromProduction = true;

      const url = 'users/ifp';

      const result = await api.create(url, null);

      this.importingFromProduction = false;

      if (result) {
        if (!result.error) {
          notifications.succeed(this.$store, "Users were successfully synced");
          await this.loadUsers();
          return;
        }
      }

      notifications.fail(this.$store, "There was an error while imiporting the users. Please try again.");
    }
  },
})
