
import { InsightUserListModel } from "@/models/users/InsightUserListModel"
import Vue from "vue"
import { PropValidator } from "vue/types/options"

export default Vue.extend({
  props: {
    user: {
      type: Object,
      default: null,
    } as PropValidator<InsightUserListModel>,
  },

  computed: {
    userStatusText(): string {
      if (this.user.status == 'active') {
        return " "
      } else if (this.user.status > "") {
        return this.user.status.substr(0,1).toUpperCase() + this.user.status.substr(1)
      } else {
        return ""
      }
    },

    userStatusColor(): string {
      if (this.user.status == 'invited') {
        return 'info'
      } else if (this.user.status == 'deleted') {
        return 'error'
      } else {
        return ''
      }
    }
  },
})
