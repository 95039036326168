import { InsightAuth, InsightAuthClientApp, NavGuard } from "insight-auth"
import { Route } from "vue-router"
import { Store } from "vuex"
import { CLIENT_CURRENT, CLIENT_INDEX_BY_CODE } from "../store/getterNames"
import { CLIENT_SET_CURRENT } from "../store/mutationNames"
import { RootState } from "../store/RootState"
import store from '../store/store'
import notifications from "../util/notifications"
import * as startup from '../util/startup'
import { FORBIDDEN } from "./routeNames"

const app: InsightAuthClientApp = {
  isAppDataLoaded: () => startup.isAppDataLoaded(store as Store<RootState>),

  loadAppData: (route) => startup.loadAppData(store as Store<RootState>, route),
  
  getCurrentClientCode: () => store.getters[CLIENT_CURRENT].code as string,

  setCurrentClientCode: (client: string) => store.commit(CLIENT_SET_CURRENT, store.getters[CLIENT_INDEX_BY_CODE](client)),

  getDefaultRouteForClient: (client: string) => `/${client}`,

  getUnauthorizedRouteRedirectTargetForClient: () => {return {name: FORBIDDEN}},

  warnUnauthorizedNavigation: () => notifications.warn(store, "You do not have permission to view the requested resource")
}

export const navGuard = (auth: InsightAuth, to: Route, from: Route, next: () => void): void => {
    NavGuard(app, auth, to, from, next)
}