
import { InsightClientListModel } from "@/models/insightClients/InsightClientListModel"
import { InsightUser } from "@/models/users/InsightUser"
import Vue from "vue"
import { PropValidator } from "vue/types/options"
import ConfirmDlg from '../common/ConfirmDlg.vue'

export default Vue.extend({
  components: {
    ConfirmDlg,
  },

  props: {
    client: {
      type: Object,
      default: null,
    } as PropValidator<InsightClientListModel>,

    selectedClientCode: {
      type: String,
      default: ''
    },

    unlinking: Boolean,

    user: {
      type: Object,
      default: null,
    } as PropValidator<InsightUser>,
  },

  data () {
    return {
      showUnlinkConfirmation: false,
    }
  },

  computed: {
    isProtectedUser(): boolean {
      return !!this.user.groups.find(g => g.client == this.client.code && g.isProtected == true)
    },

    canUnlinkFromClient(): boolean {
      const auth = this.$auth

      return (auth.hasPrivilegeAnyClient("6500") ||
        (auth.hasPrivilegeForClient(this.client.code, "6510") && !this.isProtectedUser)) &&
        !this.userIsDeleted
    },

    userIsDeleted(): boolean {
      return this.user.deletedBy > ""
    },
  },

  methods: {
    async unlinkUser (doUnlink: boolean) {
      if (!doUnlink) return;

      this.$emit('confirmed-unlink-request', this.client);
    }
  },
})
