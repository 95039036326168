import { CLIENT_CURRENT, CLIENT_INDEX_BY_CODE } from "@/store/getterNames"
import { AuthorizableRoute } from "insight-auth/src/models/AuthorizableRoute"
import { Store } from "vuex"
import * as ac from "../store/actionNames"
import * as mt from "../store/mutationNames"
import { RootState } from "../store/RootState"
import { RealtimeService } from "./realtimeUpdates"

export const isAppDataLoaded = (store: Store<RootState>) => {
  return store.state.core.appDataLoaded == "yes"
}

export const loadAppData = async (store: Store<RootState>, route: AuthorizableRoute): Promise<boolean> => {
  try {
    //Load clients
    await store.dispatch(ac.CLIENT_FETCH_ALL)

    //Load privilege tree
    await store.dispatch(ac.PRIVILEGE_FETCH_ALL)

    //Load app config
    await store.dispatch(ac.CORE_FETCH_APP_CONFIG);

    const clientCode = route.params['clientCode'] ?? '';
    if (clientCode > '') {
      const clientIdx = store.getters[CLIENT_INDEX_BY_CODE](clientCode);
      store.commit(mt.CLIENT_SET_CURRENT, clientIdx);
    }

    //Initialize real-time services
    const key = store.state.core.appConfig.realTimeKey;
    const client = store.getters[CLIENT_CURRENT];
    RealtimeService.init(key, client.code);

    store.commit(mt.CORE_SET_APP_DATA_LOAD_STATE, "yes")

    return true;
  } catch (e) {
    //There is no programmatic way at this time to differentiate between an error
    //that is thrown because of a network problem and an error that is thrown
    //because we're not logged in. So, delay the error message by three seconds to
    //give the page time to redirect if we're logging in. This will delay the display
    //of the error message in cases of real error, but prevent it from showing
    //when we don't want it to.
    setTimeout(() => store.commit(mt.CORE_SET_APP_DATA_LOAD_STATE, "failed"), 3000)

    //We can't fully process the error, so re-throw.
    throw e;
    return false;
  }
}