import api from '../../util/api'
import * as mt from "../mutationNames"
import * as ac from "../actionNames"
import * as gt from "../getterNames"
import { Group } from '../../models/groups/Group'
import { GroupListModel } from '../../models/groups/GroupListModel'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '../RootState'
import { ApiRequestParams } from '../../models/core/ApiRequestParams'


const _findGroupIndex = (arr: Group[]|GroupListModel[], groupSpec: GroupListModel) => {
  return arr.findIndex(g => {return g.slug == groupSpec.slug && g.client == groupSpec.client})
}

export class GroupState {
  groups: GroupListModel[] = [];
  openGroups: Group[] = [];
}

const getters: GetterTree<GroupState, RootState> = {
  [gt.GROUPS_FOR_CURRENT_CLIENT] (state, getters) {
    const clientCode = (getters[gt.CLIENT_CURRENT] || {}).code

    return state.groups.filter(g => g.client == clientCode)
  },

  [gt.GROUPS_OPEN_FOR_CURRENT_CLIENT] (state, getters) {
    const clientCode = (getters[gt.CLIENT_CURRENT] || {}).code

    return state.openGroups.filter(g => g.client == clientCode)
  },

  [gt.GROUPS_FOR_CLIENT] (state) {
    return (clientCode: string) => {
      return state.groups.filter(g => g.client == clientCode)
    }
  }
}

const mutations: MutationTree<GroupState> = {
    [mt.GROUP_REPLACE_ALL_FOR_CLIENT] (state, groupData: {groups: GroupListModel[], client: string}) {
        const filteredGroupList = state.groups.filter(g => g.client != groupData.client)
        const safeNewGroups = groupData.groups.filter(g => g.client == groupData.client)

        filteredGroupList.push(...safeNewGroups)

        state.groups = filteredGroupList
    },

    [mt.GROUP_ADD_OPEN_GROUP] (state, newGroup) {
        if (newGroup) {
            const idx = _findGroupIndex(state.openGroups, newGroup)

            if (idx >= 0) {
                state.openGroups.splice(idx, 1, newGroup)
            } else {
                state.openGroups.push(newGroup)
            }

            const mainListIdx = _findGroupIndex(state.groups, newGroup)

            if (mainListIdx < 0) {
                state.groups.push({
                    name: newGroup.name,
                    description: newGroup.description,
                    client: newGroup.client,
                    isProtected: newGroup.isProtected,
                    slug: newGroup.slug,
                })
            }
        } else {
            throw new Error("Invalid group to open!")
        }
    },

    [mt.GROUP_REMOVE_OPEN_GROUP] (state, spec) {
        const idx = _findGroupIndex(state.openGroups, spec)

        if (idx > -1) {
            state.openGroups.splice(idx, 1)
        } else {
            throw new Error("Open group not found! Slug: " + spec.slug)
        }
    },

    [mt.GROUP_UPDATE_OPEN_GROUP] (state, data) {
        const idx = _findGroupIndex(state.openGroups, data.oldData)
        const mainListIdx = _findGroupIndex(state.groups, data.oldData)

        if (idx > -1 && mainListIdx > -1) {
            state.openGroups.splice(idx, 1, data.newData)

            const mainEntry = state.groups[mainListIdx]

            mainEntry.name = data.newData.name,
            mainEntry.description = data.newData.description
            mainEntry.slug = data.newData.slug
            mainEntry.isProtected = data.newData.isProtected
        } else {
            throw new Error("Open group not found! Slug: " + data.oldData.slug)
        }
    },

    [mt.GROUP_PURGE] (state, data) {
        const mainIdx = _findGroupIndex(state.groups, data)
        const openIdx = _findGroupIndex(state.openGroups, data)

        if (mainIdx > -1) {
            state.groups.splice(mainIdx, 1)
        }

        if (openIdx > -1) {
            state.openGroups.splice(openIdx, 1)
        }
    },
}

const actions: ActionTree<GroupState, RootState> = {
    async [ac.GROUP_FETCH_ALL] ({commit, getters}, requestedClient) {

        const client = requestedClient || getters[gt.CLIENT_CURRENT] || {}

        const groupResponse = await api.get("insightClients/" + client.code + "/groups")

        if (!groupResponse) return;

        if (groupResponse.error) {
            return Promise.reject(groupResponse);
        }

        const groups = groupResponse.data

        commit(mt.GROUP_REPLACE_ALL_FOR_CLIENT, { client: client.code, groups })
    },

    async [ac.GROUP_LOAD_GROUP] ({commit}, groupSpec) {
        const groupResponse = await api.get("insightClients/" + groupSpec.client + "/groups/" + groupSpec.slug)

        if (!groupResponse) return;

        if (groupResponse.error) {
            return Promise.reject(groupResponse);
        }

        const group = groupResponse.data

        commit(mt.GROUP_ADD_OPEN_GROUP, group)
    },

    async [ac.GROUP_SAVE_GROUP] ({commit, state}, data) {
        const groupIdx = _findGroupIndex(state.openGroups, data)

        const urlStem = "insightclients/" + data.client + "/groups"
    
        let response;
    
        if (groupIdx >= 0) {
            response = await api.update(urlStem + "/" + data.slug, data)
        } else {
            response = await api.create(urlStem, data);
        }
    
        if (!response) return;

        if (response.error) {
            return Promise.reject(response)
        }

        if (groupIdx > -1) {
            commit(mt.GROUP_UPDATE_OPEN_GROUP, {newData: response.data, oldData: data});
        } else {
            commit(mt.GROUP_ADD_OPEN_GROUP, response.data)
        }

        return response.data
    },

    async [ac.GROUP_DELETE] ({commit}, data) {
        const url = "insightclients/" + data.group.client + "/groups/" + data.group.slug
        const params: ApiRequestParams = [["forceDelete", data.force || false]]

        const response = await api.delete(url, params)
        
        if (!response) return;

        if (response.error) {
          return Promise.reject(response)
        }
    
        commit(mt.GROUP_PURGE, data.group);
      },
}

export default {
  actions,
  mutations,
  state: new GroupState,
  getters
}