import { DeletableEntity } from "../core/DeletableEntity";
import { GroupListModel } from "../groups/GroupListModel";
import { InsightClientListModel } from "../insightClients/InsightClientListModel";
import { PrivilegeListModel } from "../privileges/PrivilegeListModel";

export class InsightUser extends DeletableEntity {
  constructor(
    public id = "",
    public name = "",
    public invitationCode = "",
    public active = false,
    public clients: InsightClientListModel[] = [],
    public groups: GroupListModel[] = [],
    public effectivePrivileges: PrivilegeListModel[] = [],
  ) {
    super();
  }
}