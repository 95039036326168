
import ConfirmDlg from "./ConfirmDlg.vue"
import Vue from "vue"

export default Vue.extend({

  components: {
    ConfirmDlg
  },

  props: {
    data: {
      type: Object,
      default: null,
    },

    formValid: Boolean,

    requestSave: Boolean,

    saveActionName: {
      type: String,
      default: '',
    },

    requestDiscard: Boolean,

    requestReload: Boolean,
  },

  data() {
    return {
      buffer: {} as unknown,
      hasUnsavedEdits: false,
      valid: true,
      showConfirmDlg: false,
      errorMessage: "",
      showError: false,
    }
  },

  watch: {
    requestSave(): void {
      if (this.requestSave == true) {
        this.trySave()
        this.$emit('update:requestSave', false)
      }
    },

    requestDiscard(): void {
      if (this.requestDiscard == true) {
        this.tryDiscard()
        this.$emit('update:requestDiscard', false)
      }
    },

    requestReload(): void {
      if (this.requestReload == true) {
        this.doReload()
        this.$emit('update:requestReload', false)
      }
    },

    valid(): void {
      this.$emit('update:form-valid', this.valid)
    },

    buffer: {
      handler(): void {
        this.hasUnsavedEdits = true;
      },
      deep: true,
    },
  },

  created(): void {
    this.$nextTick(() => {
      this.populateBuffer()
    })
  },

  methods: {
    populateBuffer(): void {
      this.buffer = JSON.parse(JSON.stringify(this.data))

      this.$nextTick(() => {
        this.hasUnsavedEdits = false
        this.errorMessage = ""
        this.showError = false
      })
    },

    handleCancelDlgResponse(userSaidYes: boolean): void {
      if (userSaidYes) {
        this.populateBuffer();
      }

      this.$emit('cancel-edits', userSaidYes)
    },

    handleFormSubmit(event: Event): void {
      event.preventDefault()
      
      this.trySave()
    },

    trySave(): void {
      this.showError = false

      if (this.valid) {
        this.$emit('save-edits', "started")

        this.$store.dispatch(this.saveActionName, this.buffer)
          .then(() => {
            this.populateBuffer()
            this.$emit('save-edits', "succeeded")
          })
          .catch(error => {
            this.errorMessage = "An error ocurred while saving. Message: " + (error.data || error.errorCause || "(No message)")
            this.showError = true;
            this.$emit('save-edits', "failed");
          })
      } else {
        this.$emit('save-edits', "failed");
      }
    },

    tryDiscard(): void {
      if (this.hasUnsavedEdits) {
        this.showConfirmDlg = true
      } else {
        this.$emit('cancel-edits', true);
      }
    },

    doReload(): void {
      this.populateBuffer()
    },
  }
})
