import * as mt from "../mutationNames"
import * as ac from "../actionNames"
import * as gt from "../getterNames"
import api from '../../util/api'
import { InsightUser } from "../../models/users/InsightUser"
import { InsightUserListModel } from "../../models/users/InsightUserListModel"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import { RootState } from "../RootState"
import { ApiRequestParams } from "../../models/core/ApiRequestParams"


const _findUserIndex = (arr: InsightUser[]|InsightUserListModel[], userSpec: {id: string}) => {
  return arr.findIndex(u => {return u.id == userSpec.id})
}

const _usersForClient = (state: UserState, clientCode: string) => {
  return state.users.filter(u => u.knownClients?.includes(clientCode))
}

const _openUsersForClient = (state: UserState, clientCode: string) => {
  return state.openUsers.filter(u => u.clients.findIndex(c => c.code == clientCode) > -1)
}

export class UserState {
  users: InsightUserListModel[] = [];
  openUsers: InsightUser[] = [];
  manageDeletedUsers = false;
  manageAllUsers = false;
}

const getters: GetterTree<UserState, RootState> = {
  [gt.USERS_FOR_CURRENT_CLIENT] (state, getters) {
    const clientCode = (getters[gt.CLIENT_CURRENT] || {}).code

    return _usersForClient(state, clientCode)
  },

  [gt.USERS_OPEN_FOR_CURRENT_CLIENT] (state, getters) {
    const clientCode = (getters[gt.CLIENT_CURRENT] || {}).code

    return _openUsersForClient(state, clientCode)
  },
}

const mutations: MutationTree<UserState> = {
  [mt.USER_LIST_SET_FOR_CLIENT] (state, userData: {client: string, users: InsightUserListModel[]}) {

    const users = state.users
    const client = userData.client

    users.forEach(u => {
      u.knownClients = u.knownClients ?? []

      const idx = u.knownClients?.indexOf(client)

      if (idx > -1) {
        u.knownClients.splice(idx, 1)
      }
    })

    const newUsers = userData.users

    newUsers.forEach(u => {
      const existingUserIdx = users.findIndex(eu => eu.id == u.id)

      if (existingUserIdx > -1) {
        u.knownClients = users[existingUserIdx].knownClients ?? []
        u.knownClients.push(client)

        users.splice(existingUserIdx, 1, u)
      } else {
        u.knownClients = [client]
        users.push(u)
      }
    });
  },

  [mt.USER_ADD_OR_REPLACE_OPEN_USER] (state, newUser?: InsightUser) {
    if (newUser) {
      const idx = _findUserIndex(state.openUsers, newUser)

      if (idx >= 0) {
        state.openUsers.splice(idx, 1, newUser)
      } else {
        state.openUsers.push(newUser)
      }

      const mainListIdx = _findUserIndex(state.users, newUser)

      const knownClients = newUser.clients.map(c => c.code)

      const status = newUser.deletedBy > '' ? "deleted" : newUser.active ? "active" : "invited"

      const mainListEntry = new InsightUserListModel(
        newUser.id,
        newUser.name,
        status,
        knownClients
      );

      if (mainListIdx < 0) {
        state.users.push(mainListEntry)
      } else {
        state.users.splice(mainListIdx, 1, mainListEntry)
      }
    } else {
      throw new Error("Invalid user to open!")
    }
  },

  [mt.USER_REMOVE_OPEN_USER] (state, spec) {
    const idx = _findUserIndex(state.openUsers, spec)

    if (idx > -1) {
      state.openUsers.splice(idx, 1)
    } else {
      throw new Error("Open user not found!")
    }
  },

  [mt.USER_REMOVE_FROM_CLIENT] (state, spec) {
    const idx = _findUserIndex(state.openUsers, spec)

    if (idx > -1) {
      const user = state.openUsers[idx]

      const clientIdx = user.clients.findIndex(c => c.code == spec.client)

      if (clientIdx > -1) {
        user.clients.splice(clientIdx, 1)

        const listUser = state.users.find(u => u.id == spec.id)

        if (listUser && listUser.knownClients) {
          const listClientIdx = listUser.knownClients.findIndex(c => c == spec.client)

          if (listClientIdx > -1) {
            listUser.knownClients.splice(listClientIdx, 1)
          }
        }
      } else {
        throw new Error("Client not found in user!")
      }
    } else {
      throw new Error("Open user not found!")
    }
  },

  [mt.USER_PURGE] (state, spec) {
    const mainIdx = _findUserIndex(state.users, spec)
    const openIdx = _findUserIndex(state.openUsers, spec)

    if (mainIdx > -1) {
      state.users.splice(mainIdx, 1)
    }

    if (openIdx > -1) {
      state.openUsers.splice(openIdx, 1)
    }
  },

  [mt.USER_SET_MANAGE_DELETED_USERS] (state, showDeletedUsers) {
    state.manageDeletedUsers = showDeletedUsers
  },

  [mt.USER_SET_MANAGE_ALL_USERS] (state, showAllUsers) {
    state.manageAllUsers = showAllUsers
  }
}

const actions: ActionTree<UserState, RootState> = {
  async [ac.USER_FETCH_ALL] ({commit, getters, state}) {

    const client = getters[gt.CLIENT_CURRENT] || {}

    const params: ApiRequestParams = [["includeDeleted", `${state.manageDeletedUsers}`]]

    const url = state.manageAllUsers ? `users` : `insightClients/${client.code}/users`;

    const userResponse = await api.get(url, params)

    if (!userResponse) return Promise.reject();

    if (userResponse.error) {
      return Promise.reject(userResponse);
    }

    const users = userResponse.data

    commit(mt.USER_LIST_SET_FOR_CLIENT, { client: client.code, users: users })
  },

  async [ac.USER_LOAD_USER] ({commit, state}, userSpec) {
    const url = state.manageAllUsers ? `users/${userSpec.id}` : `insightClients/${userSpec.client}/users/${userSpec.id}`

    const userResponse = await api.get(url)

    if (!userResponse) return Promise.reject();

    if (userResponse.error) {
      return Promise.reject(userResponse);
    }

    const user = userResponse.data

    commit(mt.USER_ADD_OR_REPLACE_OPEN_USER, user)
  },

  async [ac.USER_LINK] ({commit}, spec) {
    const url = "users/" + spec.id + "/link/" + spec.client

    const response = await api.create(url, null)
    
    if (!response) return Promise.reject();

    if (response.error) {
     return Promise.reject(response)
    }
  
    const user = response.data

    commit(mt.USER_ADD_OR_REPLACE_OPEN_USER, user)
  },

  async [ac.USER_UNLINK] ({commit}, spec) {
    const url = "users/" + spec.id + "/link/" + spec.client

    const response = await api.delete(url)
    
    if (!response) return Promise.reject();

    if (response.error) {
     return Promise.reject(response)
    }
  
    commit(mt.USER_REMOVE_FROM_CLIENT, spec);
  },

  async [ac.USER_ASSIGN_GROUP] ({commit}, spec) {
    const url = "users/" + spec.id + "/assign/" + spec.client + "/" + spec.slug

    const response = await api.create(url, null)
    
    if (!response) return Promise.reject();

    if (response.error) {
      return Promise.reject(response)
    }
  
    const user = response.data

    commit(mt.USER_ADD_OR_REPLACE_OPEN_USER, user)
  },

  async [ac.USER_REMOVE_GROUP] ({commit}, spec) {
    const url = "users/" + spec.id + "/assign/" + spec.client + "/" + spec.slug

    const response = await api.delete(url)
    
    if (!response) return Promise.reject();

    if (response.error) {
      return Promise.reject(response)
    }

    const user = response.data
  
    commit(mt.USER_ADD_OR_REPLACE_OPEN_USER, user)
  },

  async [ac.USER_INVITE] ({commit}, data) {
    const url = "users"

    const response = await api.create(url, data)

    if (!response) return Promise.reject();
    
    if (response.error) {
      return Promise.reject(response)
    }

    const user = response.data

    commit(mt.USER_ADD_OR_REPLACE_OPEN_USER, user)
  },

  async [ac.USER_DELETE] ({commit}, spec) {
    const url = "users/" + spec.id

    const response = await api.delete(url)

    if (!response) return Promise.reject();
    
    if (response.error) {
      return Promise.reject(response)
    }

    commit(mt.USER_PURGE, spec);
  },

  async [ac.USER_UNDELETE] ({commit}, spec) {
    const url = "users/" + spec.id + "/undelete"

    const response = await api.update(url, null)

    if (!response) return Promise.reject();
    
    if (response.error) {
      return Promise.reject(response)
    }

    const user = response.data

    commit(mt.USER_ADD_OR_REPLACE_OPEN_USER, user);
  },
}

export default {
 actions,
 mutations,
 state: new UserState(),
 getters
}