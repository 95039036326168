
import Vue from "vue"
import * as ac from "../../store/actionNames"
import notifications from "../../util/notifications"
import NameField from "../common/NameField.vue"
import InsightClientCodeField from "./InsightClientCodeField.vue"
import PhoneNumberField from "../common/PhoneNumberField.vue"
import EmailField from '../common/EmailField.vue'
import { Address } from "@/models/core/Address"

export default Vue.extend({
  components: {
    InsightClientCodeField,
    NameField,
    PhoneNumberField,
    EmailField,
  },
  props: {
    value: Boolean
  },
  data () {
    return {
      formValid: false,
      saveInProgress: false,
      name: "",
      code: "",
      contactAddress: new Address(),
      contactPhone: "",
      contactEmail: "",
      stateList: [
        'AB', 'BC', 'MB', 'NB', 'NF', 'NS', 'NT', 'ON', 'PE',
        'QC', 'SK', 'YT', 'AK', 'AL', 'AR', 'AS', 'AZ', 'CA',
        'CO', 'CT', 'DC', 'DE', 'FL', 'FM', 'GA', 'GU', 'HI',
        'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD',
        'ME', 'MH', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC',
        'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK',
        'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'
      ]
    }
  },
  methods: {
    saveClient () {
      this.saveInProgress = true

      this.$store.dispatch(ac.CLIENT_SAVE, {
        name: this.name,
        code: this.code,
        contactAddress: this.contactAddress,
        contactPhone: this.contactPhone,
        contactEmail: this.contactEmail,
      })
      .then(() => {
        this.saveInProgress = false
        this.resetAndClose()
      })
      .catch((e) => {
        this.saveInProgress = false

        notifications.warn(this.$store, e.data)
      })
    },

    resetAndClose () {
      this.name = ""
      this.code = ""
      this.getForm().resetValidation()
      this.$emit('input', false)
    },

    getForm(): {resetValidation: () => void} {
      return this.$refs.newClientForm as unknown as {resetValidation: () => void}
    }
  },
})
