
import * as gt from "../../store/getterNames"
import * as mt from "../../store/mutationNames"
import * as ac from "../../store/actionNames"
import * as rt from "../../router/routeNames"
import Vue from "vue"
import notifications from "../../util/notifications"

import GroupList from "./GroupList.vue"
import GroupEditor from "./GroupEditor.vue"
import GroupNew from "./GroupNew.vue"
import { GroupListModel } from "@/models/groups/GroupListModel"
import { Group } from "@/models/groups/Group"

const GROUP_TITLE = "User Groups"
const NAV_EXTERNAL = "external"
const NAV_INTERNAL = "internal"
const NAV_NONE = ""

export default Vue.extend({
  components: {
    GroupList,
    GroupEditor,
    GroupNew,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      default: '',
    },
  },
  
  data() {
    return {
      showNewGroupDlg: false,
      loadingGroups: false,
      selectedTab: 0,
      navigationSource: NAV_NONE,
      openingGroup: false,
    }
  },

  computed: {
    groups(): GroupListModel[] {
      return this.$store.getters[gt.GROUPS_FOR_CURRENT_CLIENT]
    },

    clientName(): string {
      return this.$store.getters[gt.CLIENT_CURRENT].name
    },

    openGroups(): Group[] {
      return this.$store.getters[gt.GROUPS_OPEN_FOR_CURRENT_CLIENT]
    },

    canViewGroups(): boolean {
      return this.$auth.hasPrivilegeAnyClient("5500") ||
          this.$auth.hasPrivilegeForClient(this.clientCode, "5510")
    },

    canCreateGroups(): boolean {
      return this.$auth.hasPrivilegeAnyClient("5110") ||
          this.$auth.hasPrivilegeForClient(this.clientCode, "5120")
    },
  },

  watch: {
    selectedTab(): void {
      this.setTitle(this.selectedTab)

      if (this.navigationSource == NAV_NONE) {
        this.navigationSource = NAV_INTERNAL

        this.setUrl(this.selectedTab)
      } else if (this.navigationSource == NAV_EXTERNAL) {
        this.$nextTick(() => this.navigationSource = NAV_NONE)
      }
    },

    async $route(): Promise<void> {
      if (this.navigationSource == NAV_NONE) {
        this.navigationSource = NAV_EXTERNAL

        if (this.slug) {
          await this.showGroup({slug: this.slug} as GroupListModel)
        } else {
          this.selectedTab = 0
        }
      } else if (this.navigationSource == NAV_INTERNAL) {
        this.$nextTick(() => this.navigationSource = NAV_NONE)
      }
    }
  },

  async created(): Promise<void> {
    this.$store.commit(mt.CORE_SET_PAGE_TITLE, GROUP_TITLE)

    if (this.groups.length == 0) {
      await this.loadGroups()
    }

    if (this.slug) {
      this.navigationSource = NAV_EXTERNAL
      await this.showGroup({client: this.clientCode, slug: this.slug} as GroupListModel)
    }
  },

  methods: {
    async loadGroups(): Promise<void> {
      this.loadingGroups = true

      try {
        await this.$store.dispatch(ac.GROUP_FETCH_ALL)
      } catch (e) {
        notifications.warn(this.$store, "There was an error while loading the group list.")
      }

      this.loadingGroups = false
    },

    async openGroup(group: GroupListModel): Promise<boolean> {
        if (!this.canEditGroup(group)) {
          notifications.warn(this.$store, "You do not have permission to edit this group.")
          return false
        } else {
          this.openingGroup = true

          await this.$store.dispatch(ac.GROUP_LOAD_GROUP, group)

          this.openingGroup = false

          return true
        }
    },

    async showGroup(group: GroupListModel): Promise<void> {
      const idx = this.openGroups.findIndex(g => {return g.slug == group.slug})

      if (idx >= 0) {
        this.selectedTab = idx + 1
      } else {
        const success = await this.openGroup(group)

        if (success) {
          this.showGroup(group)
        }
      }
    },

    handleTabCloseRequest(group: GroupListModel): void {
      this.$store.commit(mt.GROUP_REMOVE_OPEN_GROUP, group)
    },

    setUrl(idx: number): void {
      const adjustedIdx = idx -1

      const slug = adjustedIdx < 0 ? undefined : this.openGroups[adjustedIdx].slug

      this.$router.push({name: rt.GROUPS, params:{clientCode: this.clientCode, slug: slug as string}})
    },

    setTitle(idx: number): void {
      const adjustedIdx = idx -1

      const title = adjustedIdx < 0 ? GROUP_TITLE : this.openGroups[adjustedIdx].name

      this.$store.commit(mt.CORE_SET_PAGE_TITLE, title)
    },

    canEditGroup(group: GroupListModel): boolean {
      const auth = this.$auth

      return auth.hasPrivilegeAnyClient("5300") ||
        (auth.hasPrivilegeAnyClient("5310") && !group.isProtected) ||
        (auth.hasPrivilegeForClient(group.client, "5320") && !group.isProtected)
    },
  },
})
